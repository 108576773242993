export const infoActions = {
  SET_CLIENT_NAME_SURNAME: 'SET_CLIENT_NAME_SURNAME',
  SET_CLIENT_ADRESS: 'SET_CLIENT_ADRESS',
  SET_CLIENT_PHONE: 'SET_CLIENT_PHONE',
  SET_CLIENT_PROMOCODE: 'SET_CLIENT_PROMOCODE',
  SET_CLIENT_COUNTRY: 'SET_CLIENT_COUNTRY',
  SET_CLIENT_REGION: 'SET_CLIENT_REGION',
  SET_CLIENT_EMAIL: 'SET_CLIENT_EMAIL',
  SET_CLIENT_SHIPING_METHOD: 'SET_CLIENT_SHIPING_METHOD',
  SET_CLIENT_COMMENT: 'SET_CLIENT_COMMENT',
  SET_CLIENT_PAYMENT_SYSTEM: 'SET_CLIENT_PAYMENT_SYSTEM',
  SET_IS_NEED_CAll: 'SET_IS_NEED_CAll',
};

export const objectActions = {
  SET_OBJECT_TYPE: 'SET_OBJECT_TYPE',
  SET_TYPE_BOOK: 'SET_TYPE_BOOK',
  SET_COVER_TYPE: 'SET_COVER_TYPE',
  SET_NAME_BOOK: 'SET_NAME_BOOK',
  SET_GENDER: 'SET_GENDER',
  SET_CHARACTER: 'SET_CHARACTER',
  SET_WISH: 'SET_WISH',
  SET_COVER_COLOUR: 'SET_COVER_COLOUR',
  SET_COUNT: 'SET_COUNT',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_PRICE: 'SET_PRICE',
};

export const orderBookActions = {
  ADD_BOOK_IN_ORDER: 'ADD_BOOK_IN_ORDER',
  REMOVE_BOOK_IN_ORDER: 'REMOVE_BOOK_IN_ORDER',
  CHANGE_CURRENT_ORDER_COUNT: 'CHANGE_CURRENT_ORDER_COUNT',
  CHANGE_PRICE: 'CHANGE_PRICE',
  SET_INIT_ORDER: 'SET_INIT_ORDER',
  
};

export const modalActions = {
  SET_ARE_YOU_SURE_EXIT_MODAL: 'SET_ARE_YOU_SURE_EXIT_MODAL',
  SET_IS_PERSONAL_DATA_MODAL_VISIBLE: 'SET_IS_PERSONAL_DATA_MODAL_VISIBLE',
  SET_IS_FAQ_MODAL_VISIBLE: 'SET_IS_FAQ_MODAL_VISIBLE',
  SET_IS_SERVICE_RULES: 'SET_IS_SERVICE_RULES',
  SET_IS_DELIVERI: 'SET_IS_DELIVERI',
  SET_IS_OPEN_SLIDE: 'SET_IS_OPEN_SLIDE',
  SET_ARE_YOU_WANT_ORDERING: 'SET_ARE_YOU_WANT_ORDERING'
};

export const stepActions = {
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_MAX: 'SET_MAX',
};

export const linkActions = {
  IS_REDIRECT_TO_SMOOTH: 'IS_REDIRECT_TO_SMOOTH',
  GET_REDIRECT_LINK: 'GET_REDIRECT_LINK',
};