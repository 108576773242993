import React, { useState } from 'react'
import arrowDownImage from '../../assets/images/faq-modal__question__arrow-down.svg';
import { setIsFaqModalVisible } from './../../store/actions/modal-action/modal-action';
import { useDispatch } from 'react-redux';


function FaqModal() {
  const dispatch = useDispatch();
  const [openedQuestion, setOpenedQuestion] = useState(-1)

  const onQuestionClick = (question) => {
    setOpenedQuestion(openedQuestion === question ? -1 : question)
  }

  const closeModalSetIsFaqModalVisible = () => {
    dispatch(setIsFaqModalVisible(false));
  }

  return (
      <div className="faq-modal modal-scrolled">
        <h2>Ответы на часто задаваемые вопросы</h2>

        <div className={'faq-modal__question ' + (openedQuestion === 1 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(1)}>
            <h3>Что такое именная азбука?</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 1 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 1 ? (
              <>
                <p>Это настоящая печатная книга! Эту книгу наполняют иллюстрации про вашего ребёнка,
                  путешествующего
                  по
                  страницам книги, на каждом развороте и на обложке книги присутствует имя ребенка. В
                  конструкторе
                  на
                  сайте вы
                  можете выбрать наиболее похожего персонажа на вашего ребёнка. Далее книга автоматически
                  собирается уже с
                  выбранной вами внешностью героя и именем ребёнка.</p>

                <p>Азбука с выбранной обложкой будет доставлена курьером, готовая к тому, чтобы её
                  подарить. </p>
              </>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 2 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(2)}>
            <h3>Сколько страниц в книге?</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 2 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 2 ? (
              <p>
                Всего в книге 38 страниц, включая страницу с пожеланием, вводные страницы, страницы со всеми
                буквами алфавита, именную страницу, страницы с алфавитом.
              </p>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 3 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(3)}>
            <h3>Возможен ли у вас самовывоз?</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 3 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 3 ? (
              <>
                <p>У нас нет самовывоза, но возможны два способа доставки:</p>
                <p> 1) Курьерской службой -наши книги доставляются прямиком из типографии курьерской компанией к
                  вам
                  домой. Курьеры всегда звонят в день доставки.</p>
                <p> 2) Доставка с помощью «Почта России».</p>
              </>) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 4 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(4)}>
            <h3>Какой срок доставки?</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 4 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 4 ? (
              <>
                <p>После того, как вы оформите заказ, мы отправляем книгу на печать в типографию. Срок
                  изготовления
                  2 <strong>рабочих</strong> дня, т.к. каждую книгу нужно напечатать, выполнить переплет, дать
                  материалам
                  просохнуть, проверить качество и упаковать. Мы дорожим качеством нашей продукции, поэтому
                  срок
                  изготовления невозможно ускорить.</p>

                <p> После того, как книга будет готова, мы отправляем её в курьерскую компанию СДЭК на доставку.
                  Срок доставки по Москве и Московской области составляет 3-5 <strong>рабочих</strong> дня, в
                  другие города России
                  5-10 <strong>рабочих</strong> дней, в отдаленные регионы России
                  7-15 <strong>рабочих</strong> дней. Мы высылаем трек-номер на
                  электронную почту, чтобы вы могли отслеживать доставку заказа. </p>
              </>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 5 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(5)}>
            <h3>Где посмотреть примеры готовых книг?</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 5 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 5 ? (
              <p>Мы постоянно обновляем отзывы на наши комиксы в наших группах в соцсетях:<br/>ищите нас как @kids.vpereplet</p>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 6 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(6)}>
            <h3>Для кого подойдёт Азбука как подарок?</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 6 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 6 ? (
              <p>Именная Азбука с красочными иллюстрациями, крупными, понятными буквами алфавита и весёлыми
                стихами порадует каждого ребёнка! Книгу можно заказать собственному ребёнку или в подарок. Мы
                хотим, чтобы изучение букв было не только простым и весёлым, но ещё и запоминающимся, ведь по
                страницам книги путешествует герой, похожий на Вашего ребёнка! </p>
          ) : ''}
        </div>

        <button className="btn-green close-modal-button" onClick={() => closeModalSetIsFaqModalVisible()}>Закрыть</button>
      </div>
  )
}

export default FaqModal
