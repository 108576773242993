import React from 'react'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { mobilePages } from '../../utils/helpers'

const MenuItem = ({src, selected}) => {
  return (
      <div className={`menu-item ${selected ? 'active' : ''}`}>
        <img
            src={src}
            alt="page of book"
        />
      </div>
  )
}

class MobileBook extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pages: mobilePages(this.props.genderData, 0),
      selected: '0',
      translate: 0,
      nextPageInc: 0,
      disabled: false,
      maxOpenedPage: 0,
      width: window.innerWidth,
      height: window.innerHeight,
    }

    this.menu = null
    this.menuRef = React.createRef()



  }

  updateDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  newPagesLoad = (currentPage, translate) => {
    if (this.state.pages.length < this.props.totalPages + 1) {
      let menuItem
      this.setState(function (prevState, props) {
        let pages
        if (prevState.pages.length === 5) {
          pages = prevState.pages.concat(mobilePages(this.props.genderData, 1))
        } else if (prevState.pages.length === 10) {
          pages = prevState.pages.concat(mobilePages(this.props.genderData, 2))
        } else if (prevState.pages.length === 15) {
          pages = prevState.pages.concat(mobilePages(this.props.genderData, 3))
        } else if (prevState.pages.length === 20) {
          pages = prevState.pages.concat(mobilePages(this.props.genderData, 4))
        } else if (prevState.pages.length === 25) {
          pages = prevState.pages.concat(mobilePages(this.props.genderData, 5))
        } else if (prevState.pages.length === 30) {
          pages = prevState.pages.concat(mobilePages(this.props.genderData, 6))
        } else if (prevState.pages.length === 35) {
          pages = prevState.pages.concat(mobilePages(this.props.genderData, 7))
        }

        if (this.menuRef.current) {
          menuItem = this.maxInViewport(Object.values(this.menuRef.current.ref))

          if (menuItem) {
            return {
              translate,
              selected: menuItem.index,
              pages: pages ? pages : prevState.pages,
            }
          }
        }

        return {
          pages: pages ? pages : prevState.pages,
        }
      })
      menuItem && this.menuRef.current.onItemClick(menuItem.key)
    }
  }

  onLastItemVisible = () => {
    this.newPagesLoad(+this.state.selected)
  }

  onUpdate = ({translate}) => {
    setTimeout(() => {
      if (this.menuRef.current) {
        const menuItem = this.maxInViewport(Object.values(this.menuRef.current.ref))
        if (menuItem) {
          this.setState({translate, selected: menuItem.index})
          this.menuRef.current.onItemClick(menuItem.key)
        }
      }
    }, 500)
  }

  onSelect = key => {
    this.setState({selected: key, disabled: false})
  }

  maxInViewport = (elementsList) => {
    let minLeft = 9999, minRight = 9999, maxObj = {}

    elementsList.forEach((r) => {
      const offset = 0
      const el = r.elem

      if (!el) return false

      const left = el.getBoundingClientRect().left
      const right = el.getBoundingClientRect().right

      const leftInnerWidth = window.innerWidth - Math.abs(window.innerWidth - left - offset)
      const rightInnerWidth = Math.abs(window.innerWidth - right - offset)

      const needle = 0
      const closestLeft = [leftInnerWidth, minLeft].reduce((a, b) => {
        return Math.abs(b - needle) < Math.abs(a - needle) ? b : a
      })
      const closestRight = [rightInnerWidth, minRight].reduce((a, b) => {
        return Math.abs(b - needle) < Math.abs(a - needle) ? b : a
      })

      if (closestLeft === leftInnerWidth && closestRight === rightInnerWidth) {
        minLeft = leftInnerWidth
        minRight = rightInnerWidth
        maxObj = r
      }
    })

    return maxObj
  }

  onPrevClick = () => {
    this.menuRef.current.handleArrowClick()
  }

  onNextClick = () => {
    this.setState({disabled: true})

    try {
      this.menuRef.current.handleArrowClickRight()
    } catch (e) {
      console.log('Don\'t click so fast!')
    }
  }


  render() {
    if (this.state.width > 1250) {
      return <></>
    }

    return (
        <div>
          <div className="book-mobile-slider">
            <ScrollMenu
                inertiaScrolling={true}
                inertiaScrollingSlowdown={0.9}
                scrollToSelected={true}
                clickWhenDrag={true}
                alignCenter={true}
                data={this.state.pages.map((p, i) => <MenuItem key={i} src={p.src}/>)}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                onSelect={this.onSelect}
                onUpdate={this.onUpdate}
                ref={this.menuRef}
                selected={this.state.selected}
                transition={+0.3}
                translate={this.state.translate}
                wheel={false}
                scrollBy={1}
                onLastItemVisible={this.onLastItemVisible}
            />
            <div className="book-mobile-slider__controls">
              <button
                  className="book-slider-btn prev"
                  onClick={this.onPrevClick}
                  disabled={this.state.selected === '0'}
              >
                <i className="white-arrow-left book-step"/>
              </button>
              <div className="book__pagination">
                <div className="book__pagination__active book">{this.state.selected}</div>
                <div>/{this.props.totalPages}</div>
              </div>
              <button
                  className="book-slider-btn next"
                  onClick={this.onNextClick}
                  disabled={this.state.disabled || +this.state.selected === this.props.totalPages}
              >
                <i className="white-arrow-left book-step"/>
              </button>
            </div>
          </div>
        </div>
    )
  }
}

export default MobileBook
