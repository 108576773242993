import React, {useEffect} from 'react'
import { Field, Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {setNameBook, setGender, setCharacter, setWish} from './../../store/actions/object-action/object-action';
import { setAreYouSureExitModal } from './../../store/actions/modal-action/modal-action';
import { setCurrentStep } from './../../store/actions/step-action/step-actions';
import {defaultStepInfoState} from "../../store/reducers/step-reducer/step-reducer";
import {setCoverColour} from "../../store/actions/object-action/object-action";
import {initialWishText} from "../../utils/helpers";

function GenderStep({setStep}) {
    const dispatch = useDispatch();

    const genderInit = useSelector((state) => state.objectReducer.gender);
    const nameBookInit = useSelector((state) => state.objectReducer.name);
    const characterInit = useSelector((state) => state.objectReducer.character);

    const handleChangeWish = (name) => {
        dispatch(setWish(initialWishText(name)))
    }

    const hangleSetCurrentStep = (step) => {
        dispatch(setCurrentStep(step));
    }

    const handleSetGender = (isGirl) => {
        dispatch(setGender(isGirl ? 'W' : 'M' ));

    }
    const handleSetNameBook = (name) => {
        if (validateName(name)) return;

        dispatch(setNameBook(name));
        handleChangeWish(name);
    }

    const handleSetCharacter = (characterNumber) => {
        dispatch(setCharacter(characterNumber));
    }

    const openModalAreYouSure = () => {
        dispatch(setAreYouSureExitModal(true));
    }

    const onNextClick = (values, actions) => {
        hangleSetCurrentStep(2)
        setStep(2);
        window.ym(73927030,'reachGoal','second-step-button');
    }

    const onBackClick = () => {
        openModalAreYouSure(true);
    }

    function validateName(value) {
        let error
        if (!value) {
            error = 'Это поле необходимо заполнить'
        } else if (!/^[а-яё\s]+$/i.test(value)) {
            error = 'Введите имя ребенка на русском!'
        } else if (value.length > 11) {
            error = 'Слишком длинное имя!'
        }
        return error
    }
    useEffect( () => {
        sessionStorage.setItem('step',JSON.stringify(defaultStepInfoState));
    },[])

    useEffect(() => {
        dispatch(setCoverColour(genderInit==='W' ? 'pink' : 'blue' ));
    })

    return <Formik
        initialValues={{name_surname: nameBookInit}}
        validateOnBlur
        onSubmit={onNextClick}
    >
        {({values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty, setFieldValue}) => (
            <>
                <h1 className="header-block-title constructor">Внешний вид вашего ребёнка</h1>
                <Form className="gender_section__form">
                    <div className="gender_section__info see-the-book">
                        <label className="field__wrapper">
                            <Field name="name_surname" type="text"
                                className={!(touched.name_surname && errors.name_surname) ? 'customer-info__name see-the-book__input-name'
                                    : 'customer-info__name see-the-book__input-name error-validation'}
                                placeholder="Имя ребёнка"
                                onChange={(e) => {
                                    handleChange(e);
                                    handleSetNameBook(e.target.value.trim());
                                }}
                                onBlur={handleBlur}
                                value={values.name_surname}
                                validate={validateName}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit()
                                    }
                                }}
                            />
                            {touched.name_surname && errors.name_surname && <span className="error-validation-name">
                                {errors.name_surname}
                            </span>}
                        </label>
                        <div className="gender_section__info__toggler see-the-book__toggler">
                            <span className="gender_section__info__toggler__boy see-the-book__toggler__boy">
                                <i className="boy-sign"/>Мальчик
                            </span>
                            <span className="gender_section__info__toggler__girl see-the-book__toggler__girl">
                                <i className="girl-sign"/>Девочка
                            </span>
                            <input 
                                type="checkbox" 
                                className="toggle-button" 
                                checked={genderInit === 'W' ? true : false}
                                onChange={() => handleSetGender(genderInit === 'M' ? true : false)}
                            />
                        </div>
                    </div>

                    <div className="gender_section__image-block active">
                        <div
                            className={'gender_section__image-block__wrapper ' + (characterInit === 1 && 'active')}
                            onClick={() => {
                                handleSetCharacter(1);
                            }}
                        >
                            <i className={'gender_section__image ' + (genderInit === 'W' ?  'blond-girl' : 'boy-blond')}/>
                        </div>
                        <div
                            className={'gender_section__image-block__wrapper ' + (characterInit === 2 && 'active')}
                            onClick={() => {
                                handleSetCharacter(2);
                            }}
                        >
                            <i className={'gender_section__image ' + (genderInit === 'W' ? 'black-girl' : 'boy-black')}/>
                        </div>
                        <div
                            className={'gender_section__image-block__wrapper ' + (characterInit === 3 && 'active')}
                            onClick={() => {
                                handleSetCharacter(3);
                            }}
                        >
                            <i className={'gender_section__image ' + (genderInit === 'W' ?  'hair-3-girl' : 'boy-3')}/>
                        </div>
                    </div>

                    <div className="btns-block">
                        <div className="btn-wrapper">
                            <button className="btn-icon btn-white constructor-btn" onClick={() => onBackClick(values.name_surname)}>
                                <i className="white-arrow-left"/>
                                Назад
                            </button>
                        </div>
                        <div className="btn-wrapper">
                            <button className="btn-icon btn-green constructor-btn"
                                    disabled={!isValid && !dirty}
                                    onClick={(e) => {
                                        if (!validateName(e.target.value)) hangleSetCurrentStep(2)
                                        // dispatch(setCoverColour(isGirl ? 'pink' : 'blue' ));
                                    }

                                    }
                                    type="submit"
                                    id="second-step-link"
                            >
                                Далее <i className="white-arrow-right"/>
                            </button>
                        </div>
                    </div>
                </Form>
            </>
        )}
    </Formik>
}

export default GenderStep
