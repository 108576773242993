
import { infoActions } from './../../actions.constants';

export const setNameClient = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_NAME_SURNAME, payload });

export const setClientAdress = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_ADRESS, payload });

export const setPhone = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_PHONE, payload });

export const setPromocode = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_PROMOCODE, payload });

export const setCountryInForm = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_COUNTRY, payload });

export const setRegionInForm = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_REGION, payload });

export const setEmail = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_EMAIL, payload });

export const setShippingMethod = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_SHIPING_METHOD, payload });

export const setClientComment = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_COMMENT, payload });

export const setPaymentSystem = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_CLIENT_PAYMENT_SYSTEM, payload });

export const setIsNeedCall = (payload) => (dispatch) =>
  dispatch({ type: infoActions.SET_IS_NEED_CAll, payload });

  
