import React, {useState} from 'react'
import arrowDownImage from '../../assets/images/faq-modal__question__arrow-down.svg';
import { useDispatch } from 'react-redux';
import { setIsDelivery } from './../../store/actions/modal-action/modal-action';

function DeliveryModal({onClose}) {
    const dispatch = useDispatch();
    const [openedQuestion, setOpenedQuestion] = useState(-1)

    const onQuestionClick = (question) => {
        setOpenedQuestion(openedQuestion === question ? -1 : question)
    }

    const closeModalServiceRules = () => {
        dispatch(setIsDelivery(false));
      }

    return (
        <div className="faq-modal modal-scrolled">
            <h2>Мы доставляем наши книги по всем городам России и Украины:</h2>
            <div className="delivery_info">
                <p>На выбор нашим клиентам из Москвы, Московской области и всех остальных городов России доступна доставка до двери курьерской службой “СДЭК” или доставка “Почтой России” на их отделение. </p>
                <p><span>(!)</span> По Украине мы доставляем книги службой доставки “Новая Почта” до двери или на отделение НП.  </p>
                <p>Сроки доставки:</p>
            </div>
            <div className={'faq-modal__question ' + (openedQuestion === 1 ? 'active' : '')}>
                <div className="faq-modal__question__header link" onClick={() => onQuestionClick(1)}>
                    <h3>Курьерская служба СДЭК для заказов по России</h3>
                    <img src={arrowDownImage} alt=""  className={openedQuestion === 1 ? 'rotate-arrow' : ''}/>
                </div>

                {openedQuestion === 1 ? (
                    <>
                        <div className="delivery__wrapper">
                            <div className="delivery-region"> Москва и Московская область</div>
                            <div className="delivery__city-info">
                                <div className="delivery-time"> 3-5 рабочих дня</div>
                                <div className="price">350 ₽</div>
                            </div>
                        </div>
                        <div className="delivery__wrapper">
                            <div className="delivery-region"> Другие города России</div>
                            <div className="delivery__city-info">
                                <div className="delivery-time"> 5-10 рабочих дней</div>
                                <div className="price">400 ₽</div>
                            </div>
                        </div>

                        <div className="delivery__wrapper">
                            <div className="delivery-region"> Отдаленные регионы России*</div>
                            <div className="delivery__city-info">
                                <div className="delivery-time"> 7-15 рабочих дней</div>
                                <div className="price">1300 ₽</div>
                            </div>
                        </div>
                    </>
                ) : ''}
            </div>

            <div  className={'faq-modal__question ' + (openedQuestion === 2 ? 'active' : '')}>
                <div className="faq-modal__question__header link" onClick={() => onQuestionClick(2)}>
                    <h3>Почта России для заказов по России</h3>
                    <img src={arrowDownImage} alt=""  className={openedQuestion === 2 ? 'rotate-arrow' : ''}/>
                </div>

                {openedQuestion === 2 ? (
                    <>
                        <div className="delivery__wrapper">
                            <div className="delivery-region"> Москва и Московская область</div>
                            <div className="delivery__city-info">
                                <div className="delivery-time"> 3-5 рабочих дня</div>
                                <div className="price">от 194 ₽</div>
                            </div>
                        </div>
                        <div className="delivery__wrapper">
                            <div className="delivery-region"> Другие города России</div>
                            <div className="delivery__city-info">
                                <div className="delivery-time"> 5-12 рабочих дней</div>
                                <div className="price">от 263 ₽</div>
                            </div>
                        </div>

                        <div className="delivery__wrapper">
                            <div className="delivery-region"> Отдаленные регионы России*</div>
                            <div className="delivery__city-info">
                                <div className="delivery-time"> 7-30 рабочих днейй</div>
                                <div className="price">от 700 ₽</div>
                            </div>
                        </div>
                    </>
                ) : ''}

            </div>

            <div  className={'faq-modal__question ' + (openedQuestion === 3 ? 'active' : '')}>
                <div className="faq-modal__question__header link" onClick={() => onQuestionClick(3)}>
                    <h3>Новая Почта для заказов по Украине</h3>
                    <img src={arrowDownImage} alt=""  className={openedQuestion === 3 ? 'rotate-arrow' : ''}/>
                </div>

                {openedQuestion === 3 ? (
                    <>
                        <div className="delivery__wrapper">
                            <div className="delivery-region">Для большинства городов Украины срок доставки книги со дня заказа</div>
                            <div className="delivery__city-info">
                                <div className="delivery-time"> 5-6 рабочих дня</div>
                                <div className="price">70 ₴</div>
                            </div>
                        </div>
                    </>
                ) : ''}

            </div>
            
            {
                (openedQuestion !== -1 && openedQuestion !== 3)
                    ? ( <p className="additional-info region-info"><span>*</span> Отдаленные регионы России: Калининградская область, Хабаровский
                            край, Амурская область, Камчатский
                            край, Красноярский край, Магаданская область, Ненецкий АО, Приморский край, Республика Саха (Якутия),
                            Сахалинская область, Усть-Кутск, Чукотский АО, Ямало-Ненецкий АО
                        </p>
                    ) : (
                        ''
                    )
            }

            <p className="additional-info">Оплата заказов возможна онлайн или при получении наличными или банковской картой.</p>

            <div className="delivery_info">
                <p><span>(!)</span> Отправка заказов на почтовое отделение и в России и в Украине возможна только при 100% предоплате заказа. Если вы заказываете курьерскую службу “до двери”, то сможете оплатить заказ при получении наличными или банковской картой. </p>
            </div>
            <button className="btn-green close-modal-button" onClick={() => closeModalServiceRules()}>Закрыть</button>
        </div>
    )
}

export default DeliveryModal
