import React from 'react';
import { useDispatch } from 'react-redux';
import { setIsPersonalDataModalVisible } from './../../store/actions/modal-action/modal-action';

function PersonalDataModal() {
  const dispatch = useDispatch();

  const closeModalIsPersonalDataModalVisible = () => {
    dispatch(setIsPersonalDataModalVisible(false));
  }


  return <div className="personal-data-modal modal-scrolled">
    <h2>Соглашение об использовании персональных данных</h2>
    <div>Предоставляя свои персональные данные Покупатель даёт согласие на обработку, хранение и использование своих
      персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в следующих целях:</div>
    <div className="personal-data-modal__list">
      <ul>
        <li>Регистрации Пользователя на сайте</li>
        <li>Осуществление клиентской поддержки</li>
        <li>Получения Пользователем информации о маркетинговых событиях</li>
        <li>Выполнение Продавцом обязательств перед Покупателем</li>
        <li>Проведения аудита и прочих внутренних исследований с целью повышения качества предоставляемых услуг.</li>
      </ul>
    </div>
    <div>Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность
      Покупателя такая как:</div>
    <div className="personal-data-modal__list">
      <ul>
        <li>Фамилия, Имя, Отчество</li>
        <li>Дата рождения</li>
        <li>Контактный телефон</li>
        <li>Адрес электронной почты</li>
        <li>Почтовый адрес</li>
      </ul>
    </div>
    <div>Персональные данные Покупателей хранятся исключительно на электронных носителях и обрабатываются с использованием
      автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных
      необходима в связи с исполнением требований законодательства.

      Продавец обязуется не передавать полученные персоналпоопые данные третьим лицам, за исключением следующих
      случаев:</div>
    <div className="personal-data-modal__list">
      <ul>
        <li>По запросам уполномоченных органов государственной власти РФ только
          по основаниям и в порядке, установленным законодательством РФ
        </li>
        <li>Стратегическим партнерам, которые работают с Продавцом для предоставления
          продуктов и услуг, или тем из них, которые помогают Продавцу реализовывать
          продукты и услуги потребителям. Мы предоставляем третьим лицам минимальный
          объем персональных данных, необходимый только для оказания требуемой услуги или
          проведения необходимой транзакции.
        </li>
      </ul>
    </div>
    <div>Продавец оставляет за собой право вносить изменения в одностороннем порядке в настоящие правила, при условии, что
      изменения не противоречат действующему законодательству РФ. Изменения условий настоящих правил вступают в силу
      после их публикации на Сайте. </div>
    <button className="btn-green close-modal-button" onClick={() => closeModalIsPersonalDataModalVisible()}>Закрыть</button>
  </div>
}

export default PersonalDataModal
