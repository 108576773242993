import { infoActions } from './../../actions.constants';
import { countries } from './../../../utils/constants';
import {getStateSessionStorage, setStateSessionStorage} from "../../../utils/helpers";

export const defaultInfoClientState = {
  name_surname:getStateSessionStorage('clientInfo')['name_surname'] || '',
  address:getStateSessionStorage('clientInfo')['address'] || '',
  phone:getStateSessionStorage('clientInfo')['phone'] || null,
  promocode: getStateSessionStorage('clientInfo')['promocode'] || '',
  country: getStateSessionStorage('clientInfo')['country'] ||countries[0],
  region: getStateSessionStorage('clientInfo')['region'] || 0,
  email: getStateSessionStorage('clientInfo')['email'] || '',
  shipping_method: getStateSessionStorage('clientInfo')['shipping_method'] || 'courier',
  client_comment: getStateSessionStorage('clientInfo')['client_comment'] ||'',
  payment_system: getStateSessionStorage('clientInfo')['payment_system'] || 'online',
  need_call: getStateSessionStorage('clientInfo') ? getStateSessionStorage('clientInfo')['need_call'] : true,
 //  payment.courier: ''
};

export const infoClientReducer = (state = defaultInfoClientState, action) => {
  const { payload, type } = action;
  switch (type) {
    case infoActions.SET_CLIENT_NAME_SURNAME: {
      setStateSessionStorage('clientInfo','name_surname',payload);
      return {
        ...state,
        name_surname: payload,
      };
    }
    case infoActions.SET_CLIENT_ADRESS: {
      setStateSessionStorage('clientInfo','address',payload);
      return {
        ...state,
        adress: payload,
      };
    }
    case infoActions.SET_CLIENT_PHONE: {
      setStateSessionStorage('clientInfo','phone',payload);
      return {
        ...state,
        phone: payload,
      };
    }
    case infoActions.SET_CLIENT_PROMOCODE: {
      setStateSessionStorage('clientInfo','promocode',payload);
      return {
        ...state,
        promocode: payload,
      };
    }
    case infoActions.SET_CLIENT_COUNTRY: {
      setStateSessionStorage('clientInfo','country',payload);
      return {
        ...state,
        country: payload,
      };
    }
    case infoActions.SET_CLIENT_REGION: {
      setStateSessionStorage('clientInfo','region',payload);
      return {
        ...state,
        region: payload,
      };
    }
    case infoActions.SET_CLIENT_EMAIL: {
      setStateSessionStorage('clientInfo','email',payload);
      return {
        ...state,
        email: payload,
      };
    }
    case infoActions.SET_CLIENT_SHIPING_METHOD: {
      setStateSessionStorage('clientInfo','shipping_method',payload);
      return {
        ...state,
        shipping_method: payload,
      };
    }
    case infoActions.SET_CLIENT_COMMENT: {
      setStateSessionStorage('clientInfo','client_comment',payload);
      return {
        ...state,
        client_comment: payload,
      };
    }
    case infoActions.SET_CLIENT_PAYMENT_SYSTEM: {
      setStateSessionStorage('clientInfo','payment_system',payload);
      return {
        ...state,
        payment_system: payload,
      };
    }
    case infoActions.SET_IS_NEED_CAll: {
      setStateSessionStorage('clientInfo','need_call',payload);
      return {
        ...state,
        need_call: payload,
      };
    }
    default: {
      return state;
    }
  }
};