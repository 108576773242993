import React, { useState, useEffect } from 'react';
import { ModalContainerAreYouSure } from './components/Modal/ModalContainerAreYouSure';
import FaqModal from './components/Modal/FaqModal';
import PersonalDataModal from './components/Modal/PersonalDataModal';
import ServiceRules from './components/Modal/ServiceRules';
import Landing from './pages/Landing';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';
import CreateBook from './pages/CreateBook';
import TheFooter from './components/TheFooter';
import SlideOut from '@ijsto/react-slideout';
import Vk from './assets/images/vk.png';
import Instagram from './assets/images/instagram.svg';
import Facebook from './assets/images/facebook.svg';
import DeliveryModal from './components/Modal/DeliveryModal';
import SocialNetworkIcon from './components/SocialNetworkIcon';
import { HashLink } from 'react-router-hash-link';
import { ModalContainer } from './components/Modal/ModalContainer';
import { useSelector, useDispatch } from 'react-redux';
import {
  setIsOpenSlide,
  setIsServiceRules,
  setIsDelivery,
  setIsFaqModalVisible,
  setIsPersonalDataModalVisible,
  setAreYouSureExitModal,
  setOrderingExitModal
} from './store/actions/modal-action/modal-action';
import OrderingModal from "./components/Modal/OrderingModal";

import './assets/stylesheets/App.scss';
import {setCurrentStep} from "./store/actions/step-action/step-actions";

function App() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isConstructorOpened = location.pathname === '/create-book';

  const isServiceRulesInit = useSelector((state) => state.modalReducer.isServiceRules);
  const isDeliveryInit = useSelector((state) => state.modalReducer.isDelivery);
  const isFaqModalVisibleInit = useSelector((state) => state.modalReducer.isFaqModalVisible);
  const isPersonalDataModalVisibleInit = useSelector((state) => state.modalReducer.isPersonalDataModalVisible);
  const isAreYouSureExitModalnit = useSelector((state) => state.modalReducer.isAreYouSureExitModal);
  const isOpenSlide = useSelector((state) => state.modalReducer.isOpenSlide);
  const isOrdering = useSelector((state) => state.modalReducer.isOrdering);
  const orderLength = useSelector((state) => state.orderReducer.order.length);

  const isAnyModalOpen = 
    isAreYouSureExitModalnit ||
    isPersonalDataModalVisibleInit ||
    isFaqModalVisibleInit ||
    isServiceRulesInit ||
    isDeliveryInit;

  if (isAnyModalOpen) {
    document.body.classList.add('modal-open')
  } else {
    document.body.classList.remove('modal-open')
  }

  const openModalIsServiceRules = (arg) => {
    dispatch(setIsServiceRules(arg));
  }

  const openModalIsDelivery = (arg) => {
    dispatch(setIsDelivery(arg));
  }

  const openModalIsFaqModalVisible = (arg) => {
    dispatch(setIsFaqModalVisible(arg));
  }

  const openModalIsPersonalDataModalVisible = (arg) => {
    dispatch(setIsPersonalDataModalVisible(arg));
  }

  const openModalSetAreYouSureExitModal = (arg) => {
    dispatch(setAreYouSureExitModal(arg));
  }

  const openModalSetOrderingExitModal = (arg) => {
    dispatch(setOrderingExitModal(arg));
  }  

  const closeIsOpenSlide = () => {
    dispatch(setIsOpenSlide(false));
  }

  // const openIsOpenSlide = () => {
  //   dispatch(setIsOpenSlide(true));
  // }



  const scrollIntoView = (el) => {
    el.scrollIntoView({ behavior: location.pathname === '/' ? 'smooth' : 'instant' })
  };


  const [areYouSureExitModalFunc, setAreYouSureExitModalFunc] = useState(null)

  const redirectToSmooth = (e, link) => {
    e.preventDefault();
      setAreYouSureExitModalFunc(() => (isRedirect) => {
        // e.preventDefault();
        if (isRedirect) {
          setTimeout(() => {
            if (link){
              history.push('/')
              const offSetEl = document.querySelector(link).offsetTop;
              document.querySelector('.app').scrollTop = offSetEl;
            } else {
              history.push('/')
            }
          }, 200);
        } else {
          e.preventDefault();
        }
      })
  }

  const regExp = /\/success\/A/;
  React.useEffect(() => {
    setAreYouSureExitModalFunc(null)




    if (!regExp.test(location.pathname)) localStorage.setItem('currentOrder',JSON.stringify({}))
  }, []);



  const isConstructorOpen = location.pathname === '/create-book';

  return (
    <>
      <div className={(isOpenSlide)?"app open-menu":"app"}>
        <div className={'slideout-wrapper' + (isOpenSlide ? ' open' : '')}>
          <SlideOut
            isOpen={isOpenSlide}
            onClose={closeIsOpenSlide}
            slideFrom='top'
            offsetTop='96px'
            width='100vw'
            noCloseComponent={true}
            shouldCloseOnOverlayClick={() => false}
          >
            {
              isOpenSlide && (
                <div className="mobile-menu">
                  <ul>
                    <li onClick={() => {
                      closeIsOpenSlide();
                      openModalIsFaqModalVisible(true);
                      window.ym(73927030,'reachGoal','faq-header-button');
                    }} id="faq-modal">
                      Вопрос-ответ
                    </li>
                    <li onClick={() => {
                      closeIsOpenSlide();
                      openModalIsDelivery(true);
                      window.ym(73927030,'reachGoal','delivery-header-button');
                    }} id="delivery-modal">
                      Доставка
                    </li>
                    <HashLink
                      to='/#reviewsSection'
                      scroll={scrollIntoView}
                      onClick={(e) => {
                          if (isConstructorOpened) {
                            // if (window.innerWidth <= 815) {
                            //   handleIsRedirect(true);
                            //   handleRedirectLink('/#reviewsSection');
                            // }
                            redirectToSmooth(e, '#reviewsSection')
                            // handleRedirectLink('/#reviewsSection');
                            // if (window.innerWidth <= 815 && isRedirect) {
                            //   // handleRedirectLink('/#reviewsSection');
                            //   e.preventDefault();
                            // }
                            openModalSetAreYouSureExitModal(true);
                          }
                        closeIsOpenSlide();
                        window.ym(73927030,'reachGoal','reviews-header-button');
                      }}
                      id="reviews-link"
                    >
                      <li>Отзывы</li>
                    </HashLink>
                    <HashLink 
                      to='/#contacts'
                      scroll={scrollIntoView}
                      onClick={(e) => {
                        if (isConstructorOpened ) {
                          redirectToSmooth(e, '#contacts')
                          // if (window.innerWidth <= 815 && isRedirect) {
                          //   handleRedirectLink('/#contacts');
                          //   e.preventDefault();
                          // }
                          openModalSetAreYouSureExitModal(true);
                        }
                        closeIsOpenSlide();
                        window.ym(73927030,'reachGoal','contacts-header-button');
                      }}
                      id="contacts-link">
                      <li>
                        Контакты
                        </li>
                    </HashLink>
                  </ul>
                  <div className="socials">
                    <SocialNetworkIcon
                      link='https://vk.com/public201819098'
                      icon={Vk}
                      shadowColor={'#5489BA'}
                      bgColor={'#496a91'}/>
                    <SocialNetworkIcon
                      link='https://Instagram.com/kids.vpereplet'
                      bgColor={'#CB6666'}
                      icon={Instagram}
                      shadowColor={'#F4757A'}
                      alt='instagram'/>
                    <SocialNetworkIcon
                      link='https://www.facebook.com/Kidsvpereplet-100390195377399'
                      icon={Facebook}
                      shadowColor={'#4868AD'}
                      bgColor={'#394c83'}
                      alt='facebook'/>
                  </div>
                  {!isConstructorOpen && (
                      <HashLink to='/create-book#1' onClick={closeIsOpenSlide} className="btn-green mob-menu">
                        <button className="btn-green">
                          Создать именную книгу
                        </button>
                      </HashLink>
                  )}
                </div>
              )
            }
          </SlideOut>
          
        </div>
        <Switch>
          <Route exact path="/" render={(props) => (<Landing />)}>
          </Route>

          <Route 
            path="/create-book"
            render={(props) => (
              <CreateBook redirectToSmooth={redirectToSmooth} />
            )}
          >
          </Route>
        </Switch>
        <TheFooter/>

        <ModalContainerAreYouSure
          isOpenModal={isAreYouSureExitModalnit}
          closeModal={openModalSetAreYouSureExitModal}
          areYouSureExitModalFunc={areYouSureExitModalFunc}
          setAreYouSureExitModalFunc={setAreYouSureExitModalFunc}
        />

        <ModalContainer
          isOpenModal={isServiceRulesInit}
          closeModal={openModalIsServiceRules}
        >
          <ServiceRules />
        </ModalContainer>

        <ModalContainer
          isOpenModal={isDeliveryInit}
          closeModal={openModalIsDelivery}
        >
          <DeliveryModal />
        </ModalContainer>

        <ModalContainer
          isOpenModal={isFaqModalVisibleInit}
          closeModal={openModalIsFaqModalVisible}
        >
          <FaqModal />
        </ModalContainer>

        <ModalContainer
          isOpenModal={isFaqModalVisibleInit}
          closeModal={openModalIsFaqModalVisible}
        >
          <FaqModal />
        </ModalContainer>

        <ModalContainer
          isOpenModal={isPersonalDataModalVisibleInit}
          closeModal={openModalIsPersonalDataModalVisible}
        >
         <PersonalDataModal />
        </ModalContainer>

        <ModalContainer
            isOpenModal={isOrdering}
            closeModal={openModalSetOrderingExitModal}
        >
          <OrderingModal />
        </ModalContainer>

      </div>
    </>
  )
}

export default App
