import { combineReducers } from 'redux';
import { infoClientReducer } from './reducers/info-reducer/info-reducer';
import { objectReducer } from './reducers/object-reducer/object-reducer';
import { orderReducer } from './reducers/order-reducer/order-reducer';
import { modalReducer } from './reducers/modals-reducer/modal-reducer';
import { stepReducer } from './reducers/step-reducer/step-reducer';
import { linkReducer } from './../store/reducers/link-reducers/link-reducers';

export const rootReducer = combineReducers({
  infoClientReducer,
  objectReducer,
  orderReducer,
  modalReducer,
  stepReducer,
  linkReducer,
});