import React, { useRef, useEffect } from 'react';
import GenderStep from '../components/Steps/GenderStep';
// import Book from '../components/Book';
import WishStep from '../components/Steps/WishStep';
import CoverStep from '../components/Steps/CoverStep';
import OrderStep from '../components/Steps/OrderStep';
import SuccessStep from '../components/Steps/SuccessStep';
import TheHeader from '../components/TheHeader';
import {
  useLocation, Switch,
  Route,
} from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentStep, setMax } from './../store/actions/step-action/step-actions';
import {BookWrapperStep2} from './BookWrapperStep2';
import {setCharacter, setGender, setNameBook} from "../store/actions/object-action/object-action";
import {setInitOrder} from "../store/actions/order-action/order-action";

function CreateBook({redirectToSmooth}) {
  const dispatch = useDispatch(); 
  const location = useLocation()
  const history = useHistory()
  const regExp = /\/success\/A/;
  
  const currentStepIint = useSelector((state) => state.stepReducer.current);
  const maxInit = useSelector((state) => state.stepReducer.max);
  // const genderInit = useSelector((state) => state.objectReducer.gender);
  // const nameBookInit = useSelector((state) => state.objectReducer.name);
  // const characterInit = useSelector((state) => state.objectReducer.character);
  // const coverColourInit = useSelector((state) => state.objectReducer.cover_colour);

  const hangleSetCurrentStep = (step) => {

    dispatch(setCurrentStep(step));
  }

  const hangleSetMax = (step) => {
    dispatch(setMax(step));
  }

  useEffect(() => {
    if (currentStepIint === 0) {
      if (regExp.test(location.pathname)) {
        hangleSetCurrentStep(6);
        hangleSetMax(1);
        return ;
      }
      if (location.hash) {
        hangleSetCurrentStep(+location.hash.slice(1));
        hangleSetMax(1);
        return ;
      } else {
        hangleSetCurrentStep(1);
        hangleSetMax(1);
        return ;
      }
    }

    // return sessionStorage.removeItem('orderItem');
  }, []);

  // useEffect( () => {
  //   sessionStorage.setItem('step',JSON.stringify(defaultStepInfoState));
  //   sessionStorage.setItem('orderItem', JSON.stringify(defaultInfoState));
  //   sessionStorage.setItem('order', JSON.stringify([]));
  //
  //  return clearSessionStorage();
  // },[])
  

  useEffect(() => {
    if (+location.hash.slice(1) > maxInit) {
      hangleSetCurrentStep(maxInit);
      // setCurrentStepOld(prevStep => ({...prevStep, current: step.max}))
      window.history.pushState({}, '', location.pathname + `#${maxInit}`)
    }
  }, [location.hash, location.pathname, maxInit])

  const genderSectionRef = useRef(null)
  const constructorRef = useRef(null)

  useEffect(() => {
    if (window.innerWidth <= 815) {
      genderSectionRef.current.scrollIntoView()
    } else {
      constructorRef.current.scrollIntoView()
    }
    return history.listen((location) => {
      const newStep = +location.hash.slice(1)
      if (newStep <= maxInit) {
        hangleSetCurrentStep(newStep);
      } else {
        window.history.pushState({}, '', location.pathname );
        hangleSetCurrentStep(maxInit);
      }
    })
  },[history, maxInit])

  // const setStep = (nextStep) => {
  useEffect(() => {
      if (currentStepIint === 0) return;
      if (currentStepIint > maxInit) {
        hangleSetCurrentStep(currentStepIint);

        hangleSetMax(currentStepIint);
      } else {
        hangleSetCurrentStep(currentStepIint);
      }
      window.history.pushState({}, '', location.pathname + `#${currentStepIint}`)
  
      if (window.innerWidth <= 815) {
        genderSectionRef.current.scrollIntoView()
      } else {
        constructorRef.current.scrollIntoView()
      }
    }, [currentStepIint]);



  const jumpToStep = (nextStep) => {
    if (maxInit >= nextStep) {

      if (location.pathname.startsWith('/create-book/success/')) {
        window.history.pushState({}, '', `/create-book#1`);
        dispatch(setInitOrder([]))
        window.location.reload();
        dispatch(setCurrentStep(1));
        dispatch(setMax(1));
        dispatch(setNameBook(''));
        dispatch(setCharacter(1));
        dispatch(setGender('W'));

      } else{
        hangleSetCurrentStep(nextStep);
        window.history.pushState({}, '', `/create-book#${nextStep}`);
      }
    }
    // if (regExp.test(location.pathname)){
    //
    // }
  }


  const stepClassName = (interestedStep) => {
    const max = (currentStepIint === 6 && maxInit === 1) ? 6 : maxInit;
    if (interestedStep === currentStepIint) {
      return 'current'
    } else if (interestedStep <= max) {
      return 'done'
    } else {
      return ''
    }
  }



  function CurrentStep({step}) {
    switch (step) {
      case 1:
        return <GenderStep
        />
      case 2:
        return <>
          <BookWrapperStep2 hangleSetCurrentStep={hangleSetCurrentStep} />
        </>
      case 3:
        return <WishStep
          // setStep={setStep}
        />
      case 4:
        return <CoverStep />
      case 5:
        return <OrderStep />
      case 6:
        return <SuccessStep />
      default:
        return <></>
    }
  }

  return (
      <div className="create_book__wrapper" ref={constructorRef}>
        <TheHeader 
          redirectToSmooth={redirectToSmooth}
          isConstructor={true}
        />


        <div className="gender_section" ref={genderSectionRef}>
          <div className="gender_section__container">
            <ul className="order-steps">
              <li className={'step ' + (stepClassName(1))} onClick={() => jumpToStep(1)}>

                {currentStepIint === 1 && <span className="number-step">01</span>}
                <span className="step__text">Ваш ребёнок</span>
              </li>
              <li className={'step ' + (stepClassName(2))} onClick={() => jumpToStep(2)}>
                {currentStepIint <= 2 && <span className="number-step">02</span>}
                <span className="step__text">Ваша книга</span>
              </li>
              <li className={'step ' + (stepClassName(3))} onClick={() => jumpToStep(3)}>
                {currentStepIint <= 3 && <span className="number-step">03</span>}
                <span className="step__text">Пожелание</span>
              </li>
              <li className={'step ' + (stepClassName(4))} onClick={() => jumpToStep(4)}>
                {currentStepIint <= 4 && <span className="number-step">04</span>}
                <span className="step__text">Выбор обложки</span>
              </li>
              <li className={'step ' + (stepClassName(5))} onClick={() => jumpToStep(5)}>
                {currentStepIint <= 5 && <span className="number-step"> 05</span>}
                <span className="step__text">Заказ</span>
              </li>
            </ul>

            {/* <div className={'gender_section__content' + (step.current === 6 ? ' book-content' : '')}> */}




              <Switch>
                <Route exact path="/create-book/success/:orderId" render={(props) => (
                  <div className='gender_section__content book-content'>
                  <SuccessStep  />
                  </div>

                )}/>
                <Route exact path="/create-book" render={(props) => (
                  <div className='gender_section__content'>
                    <CurrentStep
                       {...props}
                      step={currentStepIint}
                    />
                  </div>
                )}/>
              </Switch>

            {/* </div> */}
          </div>

        </div>
      </div>
  )
}

export default CreateBook
