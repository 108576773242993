/* eslint-disable no-undef */
import React from 'react'
import { Formik, Form, Field } from 'formik'
import api from '../../utils/API'
import { useState, useEffect } from 'react';
import { regions, countries, countriesСurrency, countriesISO, mailForCountry } from '../../utils/constants';
import phone from 'phone';
import { getSale, getStateSessionStorage, initialWishText} from '../../utils/helpers';
import ErrorFocus from '../../utils/ErrorFocus';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import {
  setNameClient,
  setPhone,
  setEmail,
  setPromocode,
  setCountryInForm ,
  setRegionInForm,
  setClientComment,
  setClientAdress,
  setShippingMethod,
  setPaymentSystem,
} from './../../store/actions/info-action/info-action';
import { setIsPersonalDataModalVisible } from './../../store/actions/modal-action/modal-action'
import {setCharacter, setGender, setNameBook, setWish} from './../../store/actions/object-action/object-action';
import styles from './CheckBox.module.scss'
import {setIsNeedCall} from "../../store/actions/info-action/info-action";
import Order from "./Order/Order";
import { setCurrentStep } from "./../../store/actions/step-action/step-actions";
import { removeBookInOrder, changeCurrentOrderCount, changePriceInOrder } from './../../store/actions/order-action/order-action';
import {defaultInfoClientState} from "../../store/reducers/info-reducer/info-reducer";
// import delay from 'delay'

function OrderStep({
  genderData
}) {
  const dispatch = useDispatch();

  const nameSurnameInit = useSelector((state) => state.infoClientReducer.name_surname);
  const addressInit = useSelector((state) => state.infoClientReducer.address);
  const phoneInit = useSelector((state) => state.infoClientReducer.phone);
  const promocodeInit = useSelector((state) => state.infoClientReducer.promocode);
  const countryInit = useSelector((state) => state.infoClientReducer.country);
  const regionInit = useSelector((state) => state.infoClientReducer.region);
  const emailInit = useSelector((state) => state.infoClientReducer.email);
  const shippingMethodInit = useSelector((state) => state.infoClientReducer.shipping_method);
  const client_commentInit = useSelector((state) => state.infoClientReducer.client_comment);
  const paymentSystemInit = useSelector((state) => state.infoClientReducer.payment_system);
  const needCallInit = useSelector((state) => state.infoClientReducer.need_call);
  let order = useSelector((state) => state.orderReducer.order);

  let orderLegth = useSelector((state) => state.orderReducer.order).length;


  let countBook = useSelector((state) => {
    return state.orderReducer.order.reduce((acc, item) => acc + item.count, 0)
  });



  const [allPrice, setAllPrice] = useState(getSumPrice());

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(true)
  const [isSale, setIsSale] = useState(null)
  const [cookies, setCookie, removeCookie] = useCookies(['gender_data']);

  const [isFailPromo, setIsFailPromo] = useState(false);
  const [isApplyPromo, setIsApplyPromo] = useState(false);
  const [currentPromocode, setCurrentPromocode] = useState(null);




  const [screenSize, setScreenSize] = useState(0);

  const [isHiddenstep, setIsHiddenstep] = useState(true);

  const location = useLocation()

  const defaultCountry = countries[0]
  const initialState = {
    name_surname: nameSurnameInit,
    phone: phoneInit,
    email: emailInit,
    promocode: promocodeInit,
    client_comment: client_commentInit,
    country: countryInit || countries[0],
    regionId: regionInit,
    shipping_method: shippingMethodInit,
    address: addressInit,
    payment: getStateSessionStorage('clientInfo')['payment_system'] || 'online',
    countries,
    regions: regions.filter(r => r.country === defaultCountry),
    isSelectCountry: false,
    isSelectRegion: false,
  }

  function validateName(value) {
    let error
    if (!value) {
      error = 'Это поле необходимо заполнить'
    } else if (!/^[A-Za-zа-яё\s]+$/i.test(value)) {
      error = 'Поле должно содержать только буквы!'
    }
    return error
  }

  function validatePhone(value) {
    const phoneChecked = countriesISO.some((c) => phone(value, c).length)
    let error
    if (!value) {
      error = 'Это поле необходимо заполнить'
    } else if (!phoneChecked) {
      error = 'Неверный телефон'
    }
    return error
  }

  function validateEmail(value) {
    let error
    if (!value) {
      error = 'Это поле необходимо заполнить'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Неверный email'
    }
    return error
  }

  function validateAddress(value) {
    let error
    if (!value) {
      error = 'Это поле необходимо заполнить'
    }
    return error
  }

  const region = (regionId) => {
    return regions.find(region => region.id === regionId)
  }

  const defaultRegionByCountry = (country) => {
    return regions.find(region => region.country === country).id
  }


  useEffect(() => {
    if (promocodeInit && !!order.length) getPromoSale(promocodeInit)
  }, [])

  async function onSubmitClick(values, actions) {
    if (order.length === 0) return;

    if (isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(false)

     const currentCountOrder = order.map((item) => {
       item.count = `${item.count}`;
       item.character = `${item.character}`;
        return item
     })

      const result = await api.post('checkout', JSON.stringify({
        info: {
          name_surname: nameSurnameInit,
          address: values.address,
          phone: getNumbersPhone(phoneInit),
          promocode: promocodeInit,
          country: countryInit,
          region: regions[regionInit].title,
          email: emailInit,
          shipping_method: shippingMethodInit,
          client_comment: client_commentInit,
          payment_system: countryInit === countries[1] ? 'stripe' : 'yoomoney',
          need_call: needCallInit,
          ...(paymentSystemInit === 'cash') && {'event': 'payment.courier'},
        },
        objects: currentCountOrder,
      }));

      if (result.status === 200) {      
        // await delay(1500);

        sessionStorage.removeItem('clientInfo');

        const totalPrice = allPrice+region(values.regionId)[values.shipping_method].price - Math.round(allPrice * isSale)
        localStorage.setItem('currentOrder',JSON.stringify({
          totalPrice,
          objects: currentCountOrder,
          currency: countryInit === countries[1] ? countriesСurrency.uk : countriesСurrency.ru,
        }))








        if (values.payment === 'online') {
          // setCookie('gender_data', {
          //   totalPrice,
          //   objects: currentCountOrder,
          //   currency: countryInit === countries[1] ? countriesСurrency.uk : countriesСurrency.ru,
          // })
          window.location.replace(result.data.confirmation)
        } else {
          // setCookie('gender_data', {
          //   totalPrice,
          //   objects: currentCountOrder,
          //   currency: countryInit === countries[1] ? countriesСurrency.uk : countriesСurrency.ru,
          // })
          const path = location.pathname;
          window.location.replace(path +  `/success/${result.data.id}`)
        }
        
      } else {
        setIsSubmitButtonEnabled(true)
      }
    }

    window.ym(73927030,'reachGoal','checkout-button');
  }

  async function getPromoSale(promocode) {

    if (promocode && promocode !== '') {
      const result = await api.get(getSale(promocode.toLowerCase().trim()))
      if (result.data.status === 200) {
        handleSetPromocode(promocode.trim().toLowerCase())
        setCurrentPromocode(promocode);
        setIsSale(result.data.discount);
        setIsApplyPromo(true);
        setIsFailPromo(false);
      } else {
        handleSetPromocode('')
        setCurrentPromocode(null);
        setIsSale(null);
        setIsApplyPromo(false);
        setIsFailPromo(true);

      }
    }
  }

  function getNumbersPhone(phone) {
    const result = phone.trim().match(/[0-9]/g, '');
    return result.join('');
  }

  const setCountry = (c, setFieldValue, values) => {

    if (values.isSelectCountry) {
      const regionId = defaultRegionByCountry(c)
      setFieldValue('regionId', regionId)
      setFieldValue('regions', regions.filter(r => r.country === c))
      setFieldValue('country', c)
      setFieldValue('isSelectCountry', !values.isSelectCountry)
      
      if (!region(regionId).hasOwnProperty('mail')) {
        setFieldValue('shipping_method', 'courier');
        handleSetShippingMethod('courier');
      }
      if (!isCashPaymentPossible({...values, regionId})) {
        setFieldValue('payment', 'online');
        handleSetPaymentSystem('online');
      }
      handleSetCountry(c);
      handleSetRegion( regions.filter(r => r.country === c)[0].id)
    }
  }

  const setRegion = (r, setFieldValue, values) => {
    if (values.isSelectRegion) {
      setFieldValue('regionId', r.id)
      setFieldValue('isSelectRegion', false);
      
      handleSetRegion(regions[r.id].id)
      if (!isMailDeliveryPossible(r.id)) {
        setFieldValue('shipping_method', 'courier')
        handleSetShippingMethod('courier');
      }
      if (!isCashPaymentPossible({...values, regionId: r.id})) {
        setFieldValue('payment', 'online');
        handleSetPaymentSystem('online');

      }
    }
  }

  const getCurrrency = (values) => {
    let currency;
    if (values.country === countries[1]) {
      currency = countriesСurrency.uk;
    } else {
      currency = countriesСurrency.ru;
    }
    return currency;
  }


  function getSumPrice(values) {
    return order.reduce((acc, item) => acc + item.price * item.count , 0);
  }

  const isMailDeliveryPossible = (regionId) => {
    return region(regionId).hasOwnProperty('mail')
  }

  const isCashPaymentPossible = (values) => {
    return region(values.regionId).courierPaymentPossibility && values.shipping_method !== 'mail' && values.shipping_method !== 'pickup'
  }

  const onDeliveryChange = (values, setFieldValue) => {
    let newShippingMethod = values.shipping_method === 'courier' ? 'mail' : 'courier';
    setFieldValue('shipping_method', newShippingMethod);
    handleSetShippingMethod(newShippingMethod);
    if (!isCashPaymentPossible({...values, shipping_method: newShippingMethod})) {
      setFieldValue('payment', 'online');
      handleSetPaymentSystem('online')
    }
  }

  function handleSetPhone(phone) {
    if (validatePhone(phone)) return ;
    dispatch(setPhone(phone));
  }

  function handleSetEmail(phone) {
    if (validateEmail(phone)) return ;
    dispatch(setEmail(phone));
  }

  function handleSetNameClient(name) {
    if (validateName(name)) return ; 
    dispatch(setNameClient(name));
  }

  function handleSetClientComment(comment) {
    dispatch(setClientComment(comment))
  }

  function handleSetClientAdress(adress) {
    dispatch(setClientAdress(adress));
  }

  function handleSetPromocode(promocode) {
    dispatch(setPromocode(promocode));
  }

  function handleSetCountry(country) {
    dispatch(setCountryInForm(country));
  }

  function handleSetRegion(region) {
    dispatch(setRegionInForm(region));
  }

  function handleSetShippingMethod(method) {
    dispatch(setShippingMethod(method));
  }

  function handleSetPaymentSystem(system) {
    dispatch(setPaymentSystem(system));
  }

  function closeModalIsPersonalDataModalVisible() {
    dispatch(setIsPersonalDataModalVisible(true));
  }
  function togglerNeedCall(isCall) {
    dispatch(setIsNeedCall(isCall))
  }

  function addNewBook(){

    dispatch(setWish(initialWishText('')));
    hangleSetCurrentStep(1);
    dispatch(setNameBook(''));
    dispatch(setCharacter(1));
    dispatch(setGender('W'));
  }


  function hangleSetCurrentStep(step) {
    dispatch(setCurrentStep(step));
  }

  const removeOrder = (id) => {
    dispatch(removeBookInOrder(id));
  }

  const changeCountBooks = (payload) => {
    dispatch(changeCurrentOrderCount(payload));
  }

  useEffect(() => {
    removeCookie('gender_data');
    setScreenSize(window.innerWidth);
  },[])

  function handleResize(){
    setScreenSize(window.innerWidth);
  }

  useEffect(() => {
    setScreenSize(window.innerWidth);
     sessionStorage.setItem('clientInfo', JSON.stringify(defaultInfoClientState));
  }, [])

  useEffect(() => {
    window.addEventListener('resize',handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }

  },[]);

  useEffect(() => {
    dispatch(changePriceInOrder(countryInit));
  }, [countryInit,dispatch]);

  function OrderingBack(e) {
    e.preventDefault();
    if(!isHiddenstep){
      setIsHiddenstep(!isHiddenstep);
      return
    }
    hangleSetCurrentStep(4)
  }

  useEffect(() => {
    setAllPrice(getSumPrice())
  }, [countBook, countryInit, orderLegth])

  return <>
    {/*<h1 className="header-block-title constructor">Оформите заказ</h1>*/}


    <Formik
        initialValues={initialState}
        validateOnBlur
        onSubmit={onSubmitClick}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty,
          setFieldValue,
        }) => (

          <Form className="order-step">
            {(screenSize > 975 || isHiddenstep) &&
              <>
                <h2 className="ordersTitle">Ваш заказ</h2>
                {
                  order === 0
                    ? <></>
                    : (
                         order.map((item) => (
                        <Order
                          key={item.id}
                          id={item.id}
                          gender={item.gender}
                          name={item.name}
                          cover_colour={item.cover_colour}
                          character={item.character}
                          wish={item.wish}
                          cover_type={item.cover_type}
                          count={item.count}
                          price={item.price}
                          removeOrder={removeOrder}
                          changeCountBooks={changeCountBooks}
                        />
                      ))
                    )
                }
                <div className="add-new-book" onClick={() => addNewBook() }>
                  <span>Добавить еще книгу</span>
                </div>
              </>
            }

            <div className="form-checkWrapper">

              { (screenSize > 975 || !isHiddenstep)&&
                <div className="main-form">
                <p className="main-form__title">Кому доставить?</p>
                <label className="field__wrapper">
                  <Field
                      name="name_surname"
                      type="text"
                      className={!(touched.name_surname && errors.name_surname) ? 'customer-info__name see-the-book__input-name'
                          : 'customer-info__name see-the-book__input-name error-validation'}
                      placeholder="Имя и фамилия получателя"
                      onChange={(e) => {
                        handleChange(e)
                        handleSetNameClient(e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.name_surname}
                      validate={validateName}
                  />
                  {touched.name_surname && errors.name_surname && <span className="error-validation-name">
                                {errors.name_surname}
                            </span>}
                </label>

                <div className="customer-info__contacts-wrapper">
                  <label className="field__wrapper">
                    <Field
                        type="text"
                        name="phone"
                        className={!(touched.phone && errors.phone) ? 'customer-info__contacts see-the-book__input-name phone'
                            : 'customer-info__contacts see-the-book__input-name error-validation'}
                        placeholder="Телефон"
                        value={values.phone || ''}
                        onChange={(e) => {
                          handleChange(e);
                          handleSetPhone(e.target.value);
                        }}
                        onBlur={handleBlur}
                        validate={validatePhone}
                    />
                    {touched.phone && errors.phone &&
                    <span className="error-validation-name contact">{errors.phone}</span>}
                  </label>

                  <label className="field__wrapper">
                    <Field
                        name="email"
                        type="text"
                        className={!(touched.email && errors.email) ? 'customer-info__contacts see-the-book__input-name email'
                            : 'customer-info__contacts see-the-book__input-name email error-validation'}
                        placeholder="Email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                          handleSetEmail(e.target.value);
                        }}
                        onBlur={handleBlur}
                        validate={validateEmail}
                    />
                    {touched.email && errors.email &&
                    <span className="error-validation-name contact email">{errors.email}</span>}
                  </label>
                </div>
                <div className="promo__wrapper">
                  <label className="promocode__wrapper">
                    <button className="promo-btn" type="button"
                            onClick={() => getPromoSale(values.promocode)}>Применить
                    </button>
                    <Field
                        type="text"
                        name="promocode"
                        className='customer-info__promo see-the-book__input-name'
                        placeholder="Промокод"
                        value={values.promocode || ''}
                        onChange={(e) => {
                          handleChange(e);
                          setIsApplyPromo(false);
                          setIsFailPromo(false);
                        }}
                        onBlur={handleBlur}
                    />
                    {isFailPromo &&
                    <span className="error-validation-name promo">Неверный промокод</span>
                    }
                    {isApplyPromo &&
                    <span className="success-validation-name promo">Промокод применен</span>
                    }
                  </label>

                </div>
                <label className="field__wrapper comment">
                <textarea
                    name="client_comment"
                    type="text"
                    className="customer-info__comment see-the-book__input-name mt-0"
                    placeholder="Комментарий к заказу"
                    onChange={(e) => {
                      handleChange(e);
                      handleSetClientComment(e.target.value);
                    }}
                    value={values.client_comment}
                />
                </label>
                <div className={styles.checkBoxContainer}>

                  <input
                      className={styles.checkboxInput}
                      checked={needCallInit}
                      id="needCall"
                      type="checkbox"
                      onChange={() => togglerNeedCall(!needCallInit)}
                  />
                  <label className={styles.checkbox}
                         htmlFor="needCall">
                  <span>
                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 8.92308L9 14L18 3" stroke="white" strokeWidth="5" strokeLinecap="round"/>
                    </svg>
                  </span>
                    <span className={styles.checkboxLabelInfo}>
                    Мне нужен звонок менеджера
                  </span>
                  </label>
                </div>


                <div className="delivery__container-info">
                  <p className="main-form__title delivery">
                    Куда доставить?
                  </p>


                  <div className="customer-info__city-info">
                    <div className="selected-box">
                      <div className={!values.isSelectCountry ?
                          'options-container' : 'options-container active'}>
                        {values.countries.map((c, i) =>
                            <div
                                key={i}
                                className="option"
                                onClick={() => {
                                  // onDeliveryChange(values, setFieldValue);
                                  setCountry(c, setFieldValue, values);
                                }}>
                              <input type="radio" className="radio" id="ru" name="country" value={c}/>
                              <label htmlFor="ru">{c}</label>
                            </div>,
                        )}

                      </div>
                      <div className="selected"
                           onClick={() => setFieldValue('isSelectCountry', !values.isSelectCountry)}>{values.country}</div>
                    </div>

                    <div className="selected-box reg">
                      <div
                          className={!values.isSelectRegion ? 'options-container' : 'options-container region active'}>
                        {values.regions.map((r, i) => <div
                                key={i}
                                className="option"
                                onClick={() => {
                                  // onDeliveryChange(values, setFieldValue);
                                  setRegion(r, setFieldValue, values)
                                }}
                            >
                              <input type="radio" className="radio" id="reg1" name="regionId"/>
                              <label htmlFor="reg1">{r.title}</label>
                            </div>
                        )}
                      </div>
                      <div
                          className="selected"
                          onClick={() => {
                            setFieldValue('isSelectRegion', !values.isSelectRegion)
                          }}>
                        {region(values.regionId).title}
                      </div>
                    </div>
                  </div>

                  <div className="delivery__wrapper">
                    <div className="form_radio_btn">
                      <Field
                          id="delivery-1"
                          type="radio"
                          name="shipping_method"
                          value="courier"
                          onChange={() => {
                            onDeliveryChange(values, setFieldValue)
                          }}
                      />
                      <label htmlFor="delivery-1">
                        <div className="delivery__container">
                          {
                            values.country === countries[1] ? mailForCountry.uk[0] : mailForCountry.ru[0]
                          }
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="gender_section__info__toggler see-the-book__toggler delivery-info-mobile">
                    {/* <span
                        className={'gender_section__info__toggler__boy see-the-book__toggler__boy cover-is-chosen__toggler ' + (
                            shippingMethodInit === 'courier' ? 'active ' : ' ') + (values.regionId >= 0 && values.regionId <= 1 ? 'courier__first-third ' : ' ')}>
                          <span
                              className={values.country === countries[1] ? 'cover-is-chosen__toggler__first-line_uk' : 'cover-is-chosen__toggler__first-line'}>
                            {
                              values.country === countries[1] ? 'Новая Почта - курьером до двери' : 'Доставка курьером'
                            }
                            </span>
                    </span>
                    <span
                          className={'gender_section__info__toggler__girl see-the-book__toggler__girl cover-is-chosen__toggler ' + (
                              shippingMethodInit === 'mail' ? 'active ' : ' ') + (values.regionId >= 0 && values.regionId <= 1 ? 'courier__third-third ' : ' ')}>
                          <span className="cover-is-chosen__toggler__hard-first-line">
                            {
                              values.country === countries[1] ? 'На отделение Новой Почты ' : 'Доставка Почтой России'
                            }
                          </span>
                    </span>
                    <input type="checkbox" className="toggle-button order__toggler-button--mobile"
                          checked={shippingMethodInit !== 'courier'} name="shipping_method"
                          onChange={() => {
                            onDeliveryChange(values, setFieldValue);
                          }}
                          disabled={!region(values.regionId).hasOwnProperty('mail')}/> */}
                  </div>

                </div>
                <div className="delivery__info">
                <span className="delivery__info__title">
                  {/* {values.shipping_method === 'courier'
                      ? 'Курьерская доставка до двери'
                      : values.country === countries[1]
                          ? 'Доставка Новой Почтой'
                          : 'Доставка Почтой России'
                  } */}
                  {values.shipping_method === 'courier'
                      ? 'Курьерская доставка до двери'
                      : values.shipping_method === 'pickup'
                          ? 'Срочная печать и самовывоз'
                          : values.country === countries[1]
                              ? 'Доставка Новой Почтой'
                              : 'Доставка Почтой России'
                  }
                  {/* default delivery deadlines */}
                  {
                    region(values.regionId)[values.shipping_method].expectationFrom === region(values.regionId)[values.shipping_method].expectationTo
                        ? (<> {region(values.regionId)[values.shipping_method].expectationFrom} рабочих дней</>)
                        : (
                          <> ({region(values.regionId)[values.shipping_method].expectationFrom + '-' +
                          region(values.regionId)[values.shipping_method].expectationTo}) рабочих дней</>
                        )
                  }
                </span>
                  <div className="dashed"></div>
                  <span className="delivery__info__price">{
                    `${region(values.regionId)[values.shipping_method].price} ${getCurrrency(values)}`
                  }</span>
                </div>
                <label className="field__wrapper">
                  <Field
                      component="textarea"
                      name="address"
                      className={!(touched.address && errors.address) ? 'address-block' : 'address-block error-validation'}
                      placeholder="Адрес доставки: индекс, город, улица, дом, кв."
                      onChange={(e) => {
                        handleChange(e);
                        handleSetClientAdress(e.target.value);
                      }}
                      value={getStateSessionStorage('clientInfo')['address'] || addressInit}
                      onBlur={handleBlur}
                      validate={validateAddress}
                  />
                  {touched.address && errors.address &&
                  <span className="error-validation-name contact address">{errors.address}</span>}
                </label>

                <p className="main-form__title payment">Способ оплаты</p>

                <div className="payment">
                  <div className="form_radio_btn_payment">
                    <Field id="payment-1" type="radio" name="payment" value="online"
                           onChange={() => {
                             handleSetPaymentSystem('online');
                             setFieldValue('payment', 'online');
                           }}
                    />
                    <label htmlFor="payment-1">
                      <div className="step"></div>
                      Оплата онлайн
                    </label>
                  </div>

                  {isCashPaymentPossible(values) &&
                  <div className="form_radio_btn_payment">
                    <Field id="payment-2" type="radio" name="payment" value="cash"
                           onChange={() => {
                             handleSetPaymentSystem('cash');
                             setFieldValue('payment', 'cash');
                           }}
                    />
                    <label htmlFor="payment-2">
                      <div className="step"></div>
                      Оплата при получении</label>
                  </div>}

                </div>
              </div>
              }
              <div className="check__wrapper">
                <div className="check">
                  <div className="check__info__wrapper">
                    <div className="check__info">
                      <div className="check__info__title">Стоимость книги</div>
                      <div className="dashed"></div>
                      <div
                          className="delivery__info__price">{allPrice} {getCurrrency(values)}
                      </div>
                    </div>
                    <div className="check__info">
                      <span className="check__info__title">Доставка</span>
                      <div className="dashed"></div>
                      <span className="delivery__info__price">
                      {
                        orderLegth > 0
                          ? `${region(values.regionId)[values.shipping_method].price} ${getCurrrency(values)}`
                          : `0 ${getCurrrency(values)}`

                      }
                      </span>
                    </div>
                    {isSale && (
                        <div className="check__info">
                          <span className="check__info__title">Скидка</span>
                          <span className="discont">{isSale * 100}%</span>
                          <div className="dashed"></div>
                          <span className="delivery__info__price red">
                      {Math.round(allPrice * isSale)} {getCurrrency(values)}
                    </span>
                        </div>
                    )}
                    <div className="check__info">
                      <span className="check__info__title sum">Итого к оплате</span>
                      <div className="dashed"></div>
                      <span className="delivery__info__price sum">

                      {
                        orderLegth > 0
                          ? allPrice + region(values.regionId)[values.shipping_method].price - Math.round(allPrice * isSale) + ' ' + getCurrrency(values)
                          : `0 ${getCurrrency(values)}`

                      }
                        {/* {allPrice + region(values.regionId)[values.shipping_method].price - Math.round(allPrice * isSale)} {getCurrrency(values)} */}
                      </span>
                    </div>
                    {
                      values.shipping_method === 'pickup' &&
                      <p className='delivery__warning'>Печать 2-3 раб. дня.<br/>Cамовывоз у МЦК Крымская 29-31 декабря.<br/>Менеджер свяжется после заказа.</p>
                    }
                    <div className="btns-wrapper order-mobile">
                      <button className="btn-icon btn-white order-back-btn"
                              onClick={OrderingBack}>
                        <i className="white-arrow-left order"/>
                        Назад
                      </button>

                      <button disabled={!isHiddenstep && (!isSubmitButtonEnabled)}
                      // <button disabled={!isHiddenstep && (!isValid || !dirty ||!isSubmitButtonEnabled)}
                              onClick={(e) => {
                                if(screenSize <= 975 && isHiddenstep){
                                  e.preventDefault()
                                  setIsHiddenstep(false);
                                } else {
                                  if (!isValid || !dirty) return;
                                  handleSubmit(e)

                                }
                              }}
                              className="order-btn"
                              type="submit"
                              id="checkout-button">
                        { (screenSize <= 975 && isHiddenstep)
                            ? 'Оформить заказ'
                        : paymentSystemInit === 'online' ? 'Оплатить заказ' : 'Отправить заказ'
                        }
                      </button>
                    </div>
                    <div className="term">
                      <div>Нажимая на кнопку "Оплатить заказ", вы соглашаетесь с &nbsp;</div>
                      <span onClick={() => {
                        closeModalIsPersonalDataModalVisible(true)
                      }}>
                        условиями использования и обработки персональных данных
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ErrorFocus/>
          </Form>
      )}

    </Formik>
    <div className="btn-back__wrapper order">
      <button className="btn-icon btn-white order-back-btn"
              onClick={() => hangleSetCurrentStep(4)}>
        <i className="white-arrow-left order"/>
        Назад
      </button>
    </div>
  </>
}

export default OrderStep
