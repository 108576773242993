import React from 'react';
import styled from "styled-components";

const MyImg = styled.img`
 &:hover{
 filter: ${props => `drop-shadow(0px 10px 20px ${props.shadowColor})`};
  background:${props => `${props.shadowColor}`};;
  border-radius:50%;
  }
  &:active{
  filter:none;
    border-radius: 50%;
   background:${props => `${props.bgColor}`};;
   
`;

const SocialNetworkIcon = ({icon, shadowColor, link}) => {

    return <div>
        <a href={link} target="_blank" rel="noreferrer">
            <MyImg src={icon} shadowColor={shadowColor} alt={link}/>
        </a>
    </div>
}
export default SocialNetworkIcon
