import React from 'react';
import exitModalCross from '../../assets/images/exit-modal-cross.svg';
// import exitModalCrossBlack from '../../assets/images/modal-cross-black.svg';
import TheHeader from "../TheHeader";
import { useSelector } from 'react-redux';

export const ModalContainer = ({isOpenModal, closeModal, children}) => {
  const isAreYouSureExitModalnit = useSelector((state) => state.modalReducer.isAreYouSureExitModal);

  const handleCloseModal = () => {
    closeModal(false)
  }
  return (
    <>
      {
        isOpenModal
          ? (
            <>
              <div className='modal'>
                {
                  !isAreYouSureExitModalnit && (
                    <TheHeader 
                      parentClass="modal"
                    />
                  )
                }

                <div className="modal__exit-button" onClick={handleCloseModal}>
                  <img src={exitModalCross} alt=""/>
                </div>
                {children}
              </div>
              <div
                className='bg'
                onClick={handleCloseModal}
              ></div>
            </>
          )
          : <></>
      }
    </>
  )
}
