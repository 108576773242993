import { modalActions } from './../../actions.constants';

const defaultInfoState = {
  // areYouSureExitModalFunc: false,
  isAreYouSureExitModal: false,
  isPersonalDataModalVisible: false,
  isFaqModalVisible: false,
  isServiceRules: false,
  isDelivery: false,
  isOrdering:false,
  isOpenSlide: false,
};

export const modalReducer = (state = defaultInfoState, action) => {
  const { payload, type } = action;
  switch (type) {
    case modalActions.SET_ARE_YOU_SURE_EXIT_MODAL: {
      return {
        ...state,
        isAreYouSureExitModal: payload,
      };
    }

    case modalActions.SET_IS_PERSONAL_DATA_MODAL_VISIBLE: {
      return {
        ...state,
        isPersonalDataModalVisible: payload,
      };
    }
    case modalActions.SET_IS_FAQ_MODAL_VISIBLE: {
      return {
        ...state,
        isFaqModalVisible: payload,
      };
    }
    case modalActions.SET_IS_SERVICE_RULES: {
      return {
        ...state,
        isServiceRules: payload,
      };
    }
    case modalActions.SET_IS_DELIVERI: {
      return {
        ...state,
        isDelivery: payload,
      };
    }

    case modalActions.SET_ARE_YOU_WANT_ORDERING: {
      return {
        ...state,
        isOrdering: payload,
      };
    }
    case modalActions.SET_IS_OPEN_SLIDE: {
      return {
        ...state,
        isOpenSlide: payload,
      };
    }
    default: {
      return state;
    }
  }
};