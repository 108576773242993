import React, { useState, useEffect } from 'react';
import {imgLinkToWish} from '../../utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { setWish } from './../../store/actions/object-action/object-action';
import { setCurrentStep } from './../../store/actions/step-action/step-actions';

const maxRows = 9
const maxCharactersInLine = 34

const WishStep = () => {
  const dispatch = useDispatch();

  const wishInit = useSelector((state) => state.objectReducer.wish);

  const handleWish = (text) => {
    dispatch(setWish(text))
  }

  // const initialText = initialWishText(nameBookInit);

  useEffect(() => {
    // handleWish(getStateSessionStorage('orderItem')['wish'] ||initialWishText(nameBookInit));
    // handleWish(initialWishText(nameBookInit));
  }, [])

  // useEffect(() => {
  //   setWishText(wishInit)
  // }, [wishInit])

  // const [wishText, setWishText] = useState(getStateSessionStorage('orderItem')['wish']||initialWishText(nameBookInit));
  const [wishText, setWishText] = useState(wishInit);

  const numberOfLines = (text) => {

    const str = text || wishText;
   
    const numberOfLines = str.split(/[\n\r]/g).reduce((prev, curr) => {
      const newLinesNumber = Math.ceil(curr.length / maxCharactersInLine);
      return prev + (newLinesNumber === 0 ? 1 : newLinesNumber);
    }, 0)

    return numberOfLines;
  } 

  const isInputCorrect = (numberOfLines() <= maxRows)

  const onApplyClick = () => {
    handleWish(wishText);
    window.ym(73927030,'reachGoal','change-wish-text-button');
  }

  // const onWishTextType = (e) => {
  //   e.preventDefault();
  //
  //   const newText = e.target.value
  //   if (numberOfLines(newText) <= maxRows) {
  //     setWishText(newText)
  //   }
  // }

  const hangleSetCurrentStep = (step) => {
    dispatch(setCurrentStep(step));
  }

  return <div className="wishstep">
    <div className="wishstep__main">
      <h1 className="header-block-title constructor">Ваше пожелание</h1>
      <div className={'wishstep__main__input ' + (isInputCorrect ? '' : 'error')}>
        <div className="wishstep__main__input__settings">
          <span>Максимум {maxRows} строчек</span>
          <span>Осталось: {maxRows - numberOfLines()}</span>
        </div>
        <textarea name="" id="" cols={34} rows={9} onChange={(e) => setWishText(e.target.value)} value={wishText}/>
        <button 
          className="wishstep__main__input__accept btn-icon btn-pink"
          id="change-wish-text-button"
          disabled={!isInputCorrect || wishText === wishInit}
          onClick={onApplyClick}
        >
          Применить <i className="white-arrow-right"/>
        </button>
      </div>
      <div className="wishstep__main__result">
        <img src={imgLinkToWish(wishInit)} alt=""/>
      </div>
    </div>
    <div className="btns-block book-mobile mt-0 wish">
      <div className="btn-wrapper">
          <button className="btn-icon btn-white constructor-btn " onClick={() => hangleSetCurrentStep(2)}>
            <i className="white-arrow-left"/>
            Назад
          </button>
      </div>
      <div className="btn-wrapper">
          <button
            className="btn-icon btn-green constructor-btn"
            onClick={() => {
            // setStep(4);
            hangleSetCurrentStep(4);
            window.ym(73927030,'reachGoal','fourth-step-button');
          }} disabled={!isInputCorrect}
                  id="fourth-step-link">
            Далее <i className="white-arrow-right"/>
          </button>
      </div>
    </div>
  </div>
}

export default WishStep
