import { orderBookActions } from './../../actions.constants';
import { countries, priceBook, bookType } from './../../../utils/constants';
import {getStateSessionStorage, setInitOrdersSessionStorage, setOrdersSessionStorage} from "../../../utils/helpers";

export const defaultOrderInfoState = {
  order: getStateSessionStorage('order') || [],
};

export const orderReducer = (state = defaultOrderInfoState, action) => {
  const { payload, type } = action;
  switch (type) {
    case orderBookActions.ADD_BOOK_IN_ORDER: {
      if (!getStateSessionStorage('order')) {
        sessionStorage.setItem('order', JSON.stringify([]));
      }
      setOrdersSessionStorage(payload)
      return {
        ...state,
        order: [...state.order, payload],
      };
    }
    case orderBookActions.REMOVE_BOOK_IN_ORDER: {
      const currentOrder = state.order;
      const index = currentOrder.findIndex((item) =>  item.id === payload);
      currentOrder.splice(index, 1);


      const getNewOrders = getStateSessionStorage('order').filter((el) => el.id !== payload)
      sessionStorage.setItem('order',JSON.stringify(getNewOrders))

      return {
        ...state,
        order: currentOrder,
      };
    }
    case orderBookActions.CHANGE_PRICE: {
      const country = payload;
      let currentOrder;
      if (country === countries[1]) {
        currentOrder = state.order.map(item => {
          item.cover_type === bookType.soft
            ? item.price = priceBook.uk.soft
            :item.price = priceBook.uk.hard;
          return item;
        });
      } else {
        currentOrder = state.order.map(item => {
          item.cover_type === bookType.soft
            ? item.price = priceBook.ru.soft
            :item.price = priceBook.ru.hard;
          return item;
        });
      }
      sessionStorage.setItem('order',JSON.stringify(currentOrder))
      return {
        ...state,
        order: currentOrder,
      };
    }
    case orderBookActions.CHANGE_CURRENT_ORDER_COUNT: {
      const currentOrder = state.order;
      const index = currentOrder.findIndex((item) => item.id === payload.id);
      currentOrder[index].count = payload.count;

      sessionStorage.setItem('order',JSON.stringify(currentOrder))
      return {
        ...state,
        order: currentOrder,
      };
    }
    case orderBookActions.SET_INIT_ORDER: {
      setInitOrdersSessionStorage(payload)
      return {
        ...state,
        order: payload,
      };
    }

    default: {
      return state;
    }
  }
};