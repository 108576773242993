import React from 'react';
import styles from './arrowButton.module.scss'

export const ArrowButton = ({currentBook, setCurrentBook, isLeftArrow, genderData, setBook}) => {
  
  const handleClick = (page) => {
    setBook(page, genderData[page].id)
    setCurrentBook(page);
  }

  return (
    <>
      {
        isLeftArrow
          ? (
            <button
              onClick={() => handleClick(currentBook - 1)}
              disabled={currentBook === 0}
              className={styles.arrow}
            >
              <i className="white-arrow-left book-step"/>
            </button>
          )
          : (
            <button
              type="button"
              className={styles.arrow}
              onClick={() => handleClick(currentBook + 1)}
              disabled={currentBook >= genderData.length - 1}
            >
              <i className="white-arrow-right book-step"/>
            </button>
          )
      }
    </>
  )
}
