import React from 'react';
import exitModalCross from '../../assets/images/exit-modal-cross.svg';
// import exitModalCrossBlack from '../../assets/images/modal-cross-black.svg';
import TheHeader from "../TheHeader";
import { useSelector } from 'react-redux';
import AreYouSureExitModal from './AreYouSureExitModal';

export const ModalContainerAreYouSure = ({isOpenModal, closeModal, areYouSureExitModalFunc, setAreYouSureExitModalFunc}) => {
  const isAreYouSureExitModalnit = useSelector((state) => state.modalReducer.isAreYouSureExitModal);

  const handleCloseModal = () => {
    closeModal(false)
  }
  return (
    <>
      {
        isOpenModal
          ? (
            <>
              <div className='modal modal-are-you-sure'>
                {
                  !isAreYouSureExitModalnit && (
                    <TheHeader 
                      parentClass="modal"
                    />
                  )
                }

              <div className="modal__exit-button" onClick={handleCloseModal}>
                <img src={exitModalCross} alt=""/>
              </div>
                  <AreYouSureExitModal areYouSureExitModalFunc={areYouSureExitModalFunc} setAreYouSureExitModalFunc={setAreYouSureExitModalFunc} />
              </div>
              <div
                className='bg'
                onClick={handleCloseModal}
              ></div>
            </>
          )
          : <></>
      }
    </>
  )
}
