import React from 'react'
import HTMLFlipBook from 'react-pageflip'
import { desktopPages } from '../../utils/helpers'

const Page = React.forwardRef((props, ref) => {
  return (
      <div className="page" ref={ref}>
        <div className="page-content">
          <img
              src={props.src}
              alt="page of book"/>
        </div>
      </div>
  )
})

class DesktopBook extends React.Component {
  
  state = {
    currentPage: 0,
    pages: [],
  }
  flipBook = React.createRef()

  componentDidMount() {
    this.initializePages();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {cover_colour} = this.props.genderData;
    if (cover_colour !== prevProps.genderData.cover_colour) {
      this.initializePages();
      // this.setState({currentPage: 0});
    }
  }

  initializePages = () => {
    const {genderData} = this.props;
    const pages = desktopPages(genderData, 0)
    this.setState({pages})
  }

  prevButtonClick = () => {
    this.flipBook.current.getPageFlip().flipPrev()
  }

  loadNewPages = () => {
    this.setState(function (prevState, props) {
      let pages
      if (prevState.currentPage === 1 && prevState.pages.length <= 5) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 1))
      } else if (prevState.currentPage === 5 && prevState.pages.length <= 10) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 2))
      } else if (prevState.currentPage === 11 && prevState.pages.length <= 15) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 3))
      } else if (prevState.currentPage === 15 && prevState.pages.length <= 20) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 4))
      } else if (prevState.currentPage === 19 && prevState.pages.length <= 25) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 5))
      } else if (prevState.currentPage === 23 && prevState.pages.length <= 30) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 6))
      } else if (prevState.currentPage === 27 && prevState.pages.length <= 35) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 7))
      } else if (prevState.currentPage === 31 && prevState.pages.length <= 40) {
        pages = prevState.pages.concat(desktopPages(this.props.genderData, 8))
      }
      return {
        pages: pages ? pages : prevState.pages
      }
    })
  }

  nextButtonClick = () => {
    this.flipBook.current.getPageFlip().flipNext()
    this.loadNewPages()
  }

  onPageChanged = (e) => {
    this.setState({
      currentPage: e.data,
    }, () => {
      this.loadNewPages()
    })
  }

  render() {
    const {currentPage, pages} = this.state;
    if (!pages.length) return null
    return (
        <div className="desktop-book">
          <div className={'book-controls-desktop' + (currentPage === 0 ? ' cover-page' : '')}>
            <button onClick={this.prevButtonClick}
                    disabled={currentPage === 0}
                    className="left-arrow">
              <i className="white-arrow-left book-step"/>
            </button>

            <button type="button" className="right-arrow"
                    disabled={currentPage >= pages.length - 1}
                    onClick={this.nextButtonClick}>
              <i className="white-arrow-right book-step"/>
            </button>
          </div>
          <HTMLFlipBook
              width={519}
              height={376}
              maxShadowOpacity={0.5}
              showCover={true}
              mobileScrollSupport={true}
              onFlip={this.onPageChanged}
              className={currentPage === 0
                  ? 'demo-book cover stf__parent'
                  : currentPage === pages.length - 1
                      ? 'demo-book cover-back stf__parent'
                      : 'demo-book stf__parent'}
              ref={this.flipBook}>
            {pages.map((p, i) => <Page key={i} src={p.src}/>)}
          </HTMLFlipBook>
        </div>
    )
  }
}

export default DesktopBook
