import axios from 'axios'
import {BOOK_URL, URL} from './constants';

export const apiConfig = {
  baseURL: URL,
}
export const apiBookConfig = {
  baseURL: BOOK_URL,
}

const axiosInstance = axios.create(apiConfig)

export default axiosInstance
