
import { objectActions } from './../../actions.constants';

export const setObjectType = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_OBJECT_TYPE, payload });

export const setTypeBook = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_TYPE_BOOK, payload });

export const setCoverType = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_COVER_TYPE, payload });

export const setNameBook = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_NAME_BOOK, payload });

export const setGender = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_GENDER, payload });

export const setCharacter = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_CHARACTER, payload });

export const setWish = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_WISH, payload });

export const setCoverColour = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_COVER_COLOUR, payload });

export const setCount = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_COUNT, payload });

export const setCurrentPage = (payload) => (dispatch) =>
  dispatch({ type: objectActions.SET_CURRENT_PAGE, payload });

