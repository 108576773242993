import { useState, useEffect } from 'react'
import Vorzats from '../assets/images/Vorsatz.jpg'
import {PROMOCODE_URL} from './constants';
import {apiBookConfig} from "./API";

export const serialize = (obj) => {
  if (obj.constructor !== Object) {
    throw new Error('obj must be an instance of Object')
  }
  const str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== '') {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  }
  return str.join('&')
}

export const imgLinkToCover = ({cover_colour, name, gender, character}) => {
  return `${apiBookConfig.baseURL}?name=${name}&gender=${gender}&character=${character}&page=INIT&colour=${cover_colour}`
}

export const imgLinkToPage = (page, {name, gender, character}) => {
  return `${apiBookConfig.baseURL}?name=${name}&gender=${gender}&character=${character}&page=${page}`
}

const parseWishText = (text) => {
  if (!text) {
    return text
  }
  return text.split(/[\n\r]/g).join('-n-')
}

export const imgLinkToWish = (wishText) => {
  return `${apiBookConfig.baseURL}?page=WISH&text=${parseWishText(wishText)}`
}

export const imgLinkToLastPage = (cover_colour) => {
  return `${apiBookConfig.baseURL}?page=END&colour=${cover_colour}`
}

export const initialWishText = (name) => {
  return `${name}, привет!
Перед тобой совершенно волшебная
книга, ведь в ней живут буквы,
каждая из которых отправит тебя в
удивительное путешествие.
Это азбука про тебя.
Мы надеемся, что ты подружишься с
этими буквами, и вам будет
весело и интересно!`
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    // Starting from this width resize event will be called.
    // Shouldn't be called on mobile android.
    // Should be called for book to center on desktop.

    function handleResize() {
      const {width} = getWindowDimensions()
      if (width > 815) {
        setWindowDimensions(getWindowDimensions())
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [windowDimensions.width])

  return windowDimensions
}

export const getSale = (promocode) => {
  return `${PROMOCODE_URL}getpromocodediscount?promocode=${promocode}`
}

export const desktopPages = (genderData, couple, coupleSize = 5) => {
  const allPages = [
    {src: imgLinkToCover({...genderData, cover_colour: genderData.cover_colour})},
    {src: Vorzats},
    {src: imgLinkToWish(genderData.wish ? genderData.wish : initialWishText(genderData.name))},
    {src: imgLinkToPage('PAGE1', genderData)},
    {src: imgLinkToPage('PAGE2', genderData)},
    {src: imgLinkToPage(1, genderData)},
    {src: imgLinkToPage(2, genderData)},
    {src: imgLinkToPage(3, genderData)},
    {src: imgLinkToPage(4, genderData)},
    {src: imgLinkToPage(5, genderData)},
    {src: imgLinkToPage('6-7', genderData)},
    {src: imgLinkToPage(8, genderData)},
    {src: imgLinkToPage(9, genderData)},
    {src: imgLinkToPage(10, genderData)},
    {src: imgLinkToPage(11, genderData)},
    {src: imgLinkToPage(12, genderData)},
    {src: imgLinkToPage(13, genderData)},
    {src: imgLinkToPage(14, genderData)},
    {src: imgLinkToPage(15, genderData)},
    {src: imgLinkToPage(16, genderData)},
    {src: imgLinkToPage(17, genderData)},
    {src: imgLinkToPage(18, genderData)},
    {src: imgLinkToPage(19, genderData)},
    {src: imgLinkToPage(20, genderData)},
    {src: imgLinkToPage(21, genderData)},
    {src: imgLinkToPage(22, genderData)},
    {src: imgLinkToPage(23, genderData)},
    {src: imgLinkToPage(24, genderData)},
    {src: imgLinkToPage(25, genderData)},
    {src: imgLinkToPage(26, genderData)},
    {src: imgLinkToPage(27, genderData)},
    {src: imgLinkToPage('28-29-30', genderData)},
    {src: imgLinkToPage(31, genderData)},
    {src: imgLinkToPage(32, genderData)},
    {src: imgLinkToPage(33, genderData)},
    {src: imgLinkToPage('PAGE36', genderData)},
    {src: imgLinkToPage('NAME', genderData)},
    {src: imgLinkToPage('PAGE34', genderData)},
    {src: imgLinkToPage('PAGE35', genderData)},
    {src: imgLinkToPage('PAGE38', genderData)},
    {src: Vorzats},
    {src: imgLinkToLastPage(genderData.cover_colour)},
  ]

  const begin = couple * coupleSize;
  const end = begin + coupleSize;

  return allPages.slice(begin, end);
}

export const mobilePages = (genderData, couple, coupleSize = 5) => {
  const allPages = [
    {
      name: '0',
      'src': imgLinkToCover({...genderData, cover_colour: genderData.cover_colour}),
    }, {
      name: '1',
      'src': imgLinkToWish(genderData.wish ? genderData.wish : initialWishText(genderData.name)),
    }, {
      name: '2',
      'src': imgLinkToPage('PAGE1', genderData),
    }, {
      name: '3',
      'src': imgLinkToPage('PAGE2', genderData),
    }, {
      name: '4',
      'src': imgLinkToPage(1, genderData),
    }, {
      name: '5',
      'src': imgLinkToPage(2, genderData),
    }, {
      name: '6',
      'src': imgLinkToPage(3, genderData),
    }, {
      name: '7',
      'src': imgLinkToPage(4, genderData),
    }, {
      name: '8',
      'src': imgLinkToPage(5, genderData),
    }, {
      name: '9',
      'src': imgLinkToPage('6-7', genderData),
    }, {
      name: '10',
      'src': imgLinkToPage(8, genderData),
    }, {
      name: '11',
      'src': imgLinkToPage(9, genderData),
    }, {
      name: '12',
      'src': imgLinkToPage(10, genderData),
    }, {
      name: '13',
      'src': imgLinkToPage(11, genderData),
    }, {
      name: '14',
      'src': imgLinkToPage(12, genderData),
    }, {
      name: '15',
      'src': imgLinkToPage(13, genderData),
    }, {
      name: '16',
      'src': imgLinkToPage(14, genderData),
    }, {
      name: '17',
      'src': imgLinkToPage(15, genderData),
    }, {
      name: '18',
      'src': imgLinkToPage(16, genderData),
    }, {
      name: '19',
      'src': imgLinkToPage(17, genderData),
    }, {
      name: '20',
      'src': imgLinkToPage(18, genderData),
    }, {
      name: '21',
      'src': imgLinkToPage(19, genderData),
    }, {
      name: '22',
      'src': imgLinkToPage(20, genderData),
    }, {
      name: '23',
      'src': imgLinkToPage(21, genderData),
    }, {
      name: '24',
      'src': imgLinkToPage(22, genderData),
    }, {
      name: '25',
      'src': imgLinkToPage(23, genderData),
    }, {
      name: '26',
      'src': imgLinkToPage(24, genderData),
    }, {
      name: '27',
      'src': imgLinkToPage(25, genderData),
    }, {
      name: '28',
      'src': imgLinkToPage(26, genderData),
    }, {
      name: '29',
      'src': imgLinkToPage(27, genderData),
    }, {
      name: '30',
      'src': imgLinkToPage('28-29-30', genderData),
    }, {
      name: '31',
      'src': imgLinkToPage(31, genderData),
    }, {
      name: '32',
      'src': imgLinkToPage(32, genderData),
    }, {
      name: '33',
      'src': imgLinkToPage(33, genderData),
    }, {
      name: '34',
      'src': imgLinkToPage('PAGE36', genderData),
    }, {
      name: '35',
      'src': imgLinkToPage('NAME', genderData),
    }, {
      name: '36',
      'src': imgLinkToPage('PAGE34', genderData),
    }, {
      name: '37',
      'src': imgLinkToPage('PAGE35', genderData),
    }, {
      name: '38',
      'src': imgLinkToPage('PAGE38', genderData),
    }, {
      name: '39',
      'src': imgLinkToLastPage(genderData.cover_colour),
    },
  ]

  const begin = couple * coupleSize
  const end = begin + coupleSize

  return allPages.slice(begin, end)
}

// export const setStateSessionStorage = (key, state) => {
//   const getProperties = JSON.parse(sessionStorage.getItem('orderItem'));
//   getProperties[key]=state;
//  return sessionStorage.setItem('orderItem', JSON.stringify(getProperties))
// };

export const setStateSessionStorage = (itemName, key, state) => {
  // debugger
  const getProperties = JSON.parse(sessionStorage.getItem(itemName));
  getProperties[key]=state;
  return sessionStorage.setItem(itemName, JSON.stringify(getProperties))
};

export const setStepSessionStorage = (key, state) => {
  const getProperties = JSON.parse(sessionStorage.getItem('step'));
  getProperties[key]=state;
  return sessionStorage.setItem('step', JSON.stringify(getProperties))
};

export const setOrdersSessionStorage = (state) => {
  const getProperties = JSON.parse(sessionStorage.getItem('order'));
  let newOrder=[...getProperties, state];
  return sessionStorage.setItem('order',JSON.stringify( newOrder))
};

export const setInitOrdersSessionStorage = (state) => {
  sessionStorage.removeItem('order')
  return sessionStorage.setItem('order',JSON.stringify( state))
};

export const getStateSessionStorage = (key) => {
 return sessionStorage.getItem(key)
     ? JSON.parse(sessionStorage.getItem(key))
     : false;
};
