import React from 'react';
import crocodile from '../../assets/images/ordering-modal-cr.svg'
import {useDispatch, useSelector} from 'react-redux';
import {setOrderingExitModal} from './../../store/actions/modal-action/modal-action';
import { addBookInOrder } from "../../store/actions/order-action/order-action";
import { setCurrentStep } from './../../store/actions/step-action/step-actions';
import { priceBook, bookType, skuType } from './../../utils/constants';
import { initialWishText } from './../../utils/helpers';
import uniqid from 'uniqid';
import {defaultInfoStateSessionStorage} from "../../store/reducers/object-reducer/object-reducer";
import {setNameBook, setWish} from '../../store/actions/object-action/object-action';

function OrderingModal() {
  const dispatch = useDispatch();

  const gender = useSelector((state) => state.objectReducer.gender);
  const nameBook = useSelector((state) => state.objectReducer.name);
  const cover_colour = useSelector((state) => state.objectReducer.cover_colour);
  const cover_type = useSelector((state) => state.objectReducer.cover_type);
  const character = useSelector((state) => state.objectReducer.character);
  const wish = useSelector((state) => state.objectReducer.wish);

  const getSumPrice = () => {
    return cover_type === 'hard' ? priceBook.ru.hard : priceBook.ru.soft
  }

  const hangleSetCurrentStep = (step) => {
    dispatch(setCurrentStep(step));
  }

  const setObjectType = () => {
    if (cover_type === bookType.hard) return skuType.sku1;
    if (cover_type === bookType.soft) return skuType.sku2;
  }

  const createOrder = () => {
    dispatch(addBookInOrder({ 
      id: uniqid(),
      object_type: setObjectType(),
      gender,
      name: nameBook,
      character,
      cover_colour,
      wish: wish,
      cover_type,
      count: 1,
      price: getSumPrice(),
    }));
  }

  const createNewBook = () => {
    createOrder();
    sessionStorage.setItem('orderItem', JSON.stringify(defaultInfoStateSessionStorage))
    dispatch(setOrderingExitModal(false));
    dispatch(setWish(initialWishText('')));
    dispatch(setNameBook(''));

    hangleSetCurrentStep(1);

  }

  const closeOrderingModal = () => {
    createOrder();
    dispatch(setOrderingExitModal(false));
    // dispatch(setWish(initialWishText('')))
    hangleSetCurrentStep(5);

  }

  return (
    <div className="are-you-sure-exit-modal ordering-modal">
      <img src={crocodile} alt="crocodile"/>
      <h2 className='modal-title'>Ваша книга готова!</h2>
      <p className='modal-info'>Хотите оформить заказ или создать еще одну книгу?</p>
      <div className="ordering-modal__controls">
        <button className="btn-pink" onClick={createNewBook}>Создать ещё книгу</button>
        <button className="btn-green" onClick={closeOrderingModal}>Оформить заказ</button>
      </div>
    </div>
  )
}

export default OrderingModal;
