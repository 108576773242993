import React, {useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import {ArrowButton} from './../../ArrowButton/ArrowButton'
import 'swiper/swiper.min.css';
import styles from './OrderList.module.scss';
import {imgLinkToCover} from "../../../utils/helpers";

import SwiperCore, { Keyboard, Pagination, Navigation, Mousewheel } from "swiper";
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);


const OrderList = ({genderData, setBookID, setDownloadBook}) => {
    
    
    const [currentBook, setCurrentBook] = useState(0);
    const [swiper, setSwiper] = useState(null);

    const slideTo = (index) => swiper.slideTo(index);

    const setBook = (index, id) => {
        slideTo(index)
        if (currentBook === index) return;
        setCurrentBook(index);
        setBookID(id);
        setDownloadBook(true);
    }


    return (
        <div className={styles.orderList__wrapper}>
                        <ArrowButton
                currentBook={currentBook}
                setCurrentBook={setCurrentBook}
                isLeftArrow={true}
                genderData={genderData}
                setBook={setBook}
            />
            <Swiper
                onSwiper={setSwiper}
                // paginationClickable={true}
                spaceBetween={15}
                slidesPerView={2}
                centeredSlides={currentBook !== 0 && currentBook !== genderData.length - 1 &&  genderData.length > 3 ? true : false}

            >


                {genderData.map((book, index) => {
                  return (
                    <SwiperSlide
                        className = {
                            index === currentBook
                            ? `${styles.orderItem} ${styles.orderItemActive}`
                            : styles.orderItem
                        }
                        key = {index}
                        onClick={() => setBook(index, book.id)}>

                        <img
                            src={imgLinkToCover({
                                character: book.character,
                                cover_colour: book.cover_colour,
                                gender: book.gender,
                                name:book.name,
                                page: "INIT",
                            })}
                            alt=""
                        />

                    </SwiperSlide>
                  )
                })}
            </Swiper>
            <ArrowButton
                currentBook={currentBook}
                setCurrentBook={setCurrentBook}
                isLeftArrow={false}
                genderData={genderData}
                setBook={setBook}
            />

        </div>
    );
};

export default OrderList;