import { stepActions } from '../../actions.constants';
import {getStateSessionStorage, setStepSessionStorage} from "../../../utils/helpers";

export const defaultStepInfoState = {
  current: getStateSessionStorage('step')['current'] || 0,
  max: getStateSessionStorage('step')['max'] || 1
};

export const defaultStepInfoStateSessionStorage ={
  current:  0,
  max:  1
}

export const stepReducer = (state = defaultStepInfoState, action) => {
  const { payload, type } = action;
  switch (type) {
    case stepActions.SET_CURRENT_STEP: {
      setStepSessionStorage('current',payload)
      return {
        ...state,
        current: payload,
      };
    }
    case stepActions.SET_MAX: {
      setStepSessionStorage('max',payload)
      return {
        ...state,
        max: payload,
      };
    }
    default: {
      return state;
    }
  }
};