import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux'
import { store } from './store/store';

ReactDOM.render(
    <React.StrictMode>
      <CookiesProvider>
        <Provider store={store}>
          <Router>
            <App/>
          </Router>
        </Provider>
      </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)

reportWebVitals()
