import { modalActions } from './../../actions.constants';

export const setIsPersonalDataModalVisible = (payload) => (dispatch) =>
  dispatch({ type: modalActions.SET_IS_PERSONAL_DATA_MODAL_VISIBLE, payload });

export const setIsFaqModalVisible = (payload) => (dispatch) =>
  dispatch({ type: modalActions.SET_IS_FAQ_MODAL_VISIBLE, payload });

export const setIsServiceRules = (payload) => (dispatch) =>
  dispatch({ type: modalActions.SET_IS_SERVICE_RULES, payload });

export const setIsDelivery = (payload) => (dispatch) =>
  dispatch({ type: modalActions.SET_IS_DELIVERI, payload });

export const setAreYouSureExitModal = (payload) => (dispatch) =>
  dispatch({ type: modalActions.SET_ARE_YOU_SURE_EXIT_MODAL, payload });

export const setIsOpenSlide = (payload) => (dispatch) =>
  dispatch({ type: modalActions.SET_IS_OPEN_SLIDE, payload });

export const setOrderingExitModal = (payload) => (dispatch) =>
    dispatch({ type: modalActions.SET_ARE_YOU_WANT_ORDERING, payload });
