import React, { useEffect, useState } from 'react'
import Book from '../Book'
import { useParams } from 'react-router'
import { useCookies } from 'react-cookie'
import OrderList from "./OrderList/OrderList";
import {getStateSessionStorage} from "../../utils/helpers";
import {setCurrentStep, setMax} from './../../store/actions/step-action/step-actions';
import { useDispatch } from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {setInitOrder} from "../../store/actions/order-action/order-action";
import {countriesСurrency} from "../../utils/constants";
import {setCharacter, setGender, setNameBook} from "../../store/actions/object-action/object-action";
import {defaultStepInfoStateSessionStorage} from './../../store/reducers/step-reducer/step-reducer';

function SuccessStep() {
  const dispatch = useDispatch();
  const location = useLocation();

  let {orderId} = useParams();

  const [cookies, setCookie, removeCookie] = useCookies(['gender_data']);

  const booksListSessionStorage = JSON.parse(localStorage.getItem('currentOrder'));
  // const booksListSessionStorage = cookies.gender_data.objects;
    // if ( !!getStateSessionStorage('order') === !![]) {
    //
    // }

    const [currentBookView, setCurrentBookView] = useState(booksListSessionStorage.objects.length !== 0 ? booksListSessionStorage.objects[0].id : []);
    const [screenSize, setScreenSize] = useState(0);
    const [downloadBook, setDownloadBook] = useState(false);
    function handleResize() {
      setScreenSize(window.innerWidth);
      if (window.innerWidth <= 1249) {
        setDownloadBook(true);
        setTimeout(() => setDownloadBook(false), 50);
      }
    }

    useEffect(() => {
      sessionStorage.setItem('step',JSON.stringify(defaultStepInfoStateSessionStorage));
    })

  useEffect(() => {
    dispatch(setCurrentStep(6));
  });

  useEffect(() => {
    setScreenSize(window.innerWidth);
    dispatch(setInitOrder([]))
    sessionStorage.setItem('order', JSON.stringify([]));
  }, [])

  useEffect(()=>{
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize)
      }
  },[]);

  useEffect(() => {
    window.fbq('track', 'Purchase', {value: booksListSessionStorage.totalPrice, currency: booksListSessionStorage.currency === countriesСurrency.uk ? 'UAH' : 'RUB'});
    window.gtag('event', 'Заказ успешно оформлен', {
      'event_category': 'page',
      'event_label': 'success_page'
    });
    window.ym(73927030,'reachGoal','success-page-opened');
  })

  useEffect(() => {
    setDownloadBook(false);
  },[currentBookView])


  const setBookID = (id) =>{
    setCurrentBookView(id);
  }

  const lookForCurrentBookID = (id) =>{
    const viewBook = booksListSessionStorage.objects.find((book) => book.id === id)
    const newData= {
        character: viewBook.character,
        cover_colour: viewBook.cover_colour,
        gender: viewBook.gender,
        name: viewBook.name,
        wish: viewBook.wish,
        page: "INIT"
      }
    return newData;
  
}

  return (
      <>
        <h1 className="success-step__text-thanks">
          Благодарим вас за заказ!
        </h1>
        <div className="success-step__text">
          Ваш номер заказа: <span>{orderId}</span>
        </div>
        {screenSize >= 1249
          ? (
            <>
              <div className="success-step__text title">
                Ваши книги:
              </div>

              <OrderList 
                genderData={booksListSessionStorage.objects}
                setBookID={setBookID}
                setDownloadBook={setDownloadBook}
              />


              {
                downloadBook
                  ? <div style={{height: '376px'}}></div>
                  : <Book genderData={lookForCurrentBookID(currentBookView)} 
                      parentClass="success-step" 
                      // colour={'pink'}
                    />
              }
            </>
          )
          : (
            <>
              {
                // downloadBook
                  // ? <></>
                  booksListSessionStorage.objects.map((book, index) => {
                      return(
                        <>
                          <div className="success-step__text title">
                              Ваши книга {book.name} :
                          </div>
          
                          <Book
                            genderData={lookForCurrentBookID(book.id)}
                            parentClass="success-step"
                            //  colour={'pink'}
                            key = {book.id}
                          />
                        </>
                    )})
              }
            </>
          )
   
        }
       
        <div className="success-step__text success-step__text-footer service">
          В ближайшее время менеджер свяжется для подтверждения заказа и
          предоставления деталей по заказу.
        </div>
        <div className="success-step__text success-step__text-footer">
          Надеемся, что данная книга порадует каждого ребёнка, а также поможет увлекательно и легко изучить буквы!
        </div>
        <div className="success-step__text success-step__text-footer">
          Будем рады вам снова!
        </div>
         <Link to="/create-book#1" onClick={() => {
             dispatch(setInitOrder([]));
             sessionStorage.setItem('order', JSON.stringify([]));
         }}>
            <button className="btn-green success" onClick={() => {
              removeCookie('gender_data');
              dispatch(setCurrentStep(1));
                dispatch(setMax(1));
                dispatch(setNameBook(''));
                dispatch(setCharacter(1));
                dispatch(setGender('W'));
            }}>
              Заказать ещё книгу
            </button>
         </Link>
      </>
  )
}

export default SuccessStep
