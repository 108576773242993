
import { orderBookActions } from './../../actions.constants';

export const addBookInOrder = (payload) => (dispatch) =>
  dispatch({ type: orderBookActions.ADD_BOOK_IN_ORDER, payload });

export const removeBookInOrder = (payload) => (dispatch) =>
  dispatch({ type: orderBookActions.REMOVE_BOOK_IN_ORDER, payload });

export const changeCurrentOrderCount = (payload) => (dispatch) =>
  dispatch({ type: orderBookActions.CHANGE_CURRENT_ORDER_COUNT, payload });

export const changePriceInOrder = (payload) => (dispatch) =>
  dispatch({ type: orderBookActions.CHANGE_PRICE, payload });

export const setInitOrder = (payload) => (dispatch) =>
    dispatch({ type: orderBookActions.SET_INIT_ORDER, payload });





  