import React from 'react';
import DesktopBook from './Book/DesktopBook';
import MobileBook from './Book/MobileBook';
const Book = ({genderData,  parentClass}) => {
  const firstPage = 0;
  const totalPages = 39;


  return (
      <div className={'the-book-step ' + (parentClass ? parentClass : '')}>
         <DesktopBook
              genderData={{
                character: genderData.character,
                cover_colour: genderData.cover_colour,
                gender: genderData.gender,
                name: genderData.name,
                wish: genderData.wish,
                page: "INIT",
              }}
              colour={genderData.cover_colour}
        />

      <MobileBook
          firstPage={firstPage}
          totalPages={totalPages}
          genderData={{
            character: genderData.character,
            cover_colour: genderData.cover_colour,
            gender: genderData.gender,
            name: genderData.name,
            wish: genderData.wish,
            page: "INIT",
          }}
          colour={genderData.cover_colour}
        />
      </div>
  )
}

export default Book
