import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {setNameBook, setGender, setWish} from './../store/actions/object-action/object-action';
import {initialWishText} from "../utils/helpers";

function SeeTheBook({parentClass, onClickFunction}) {
  const dispatch = useDispatch();
  const genderInit = useSelector((state) => state.objectReducer.gender);
  const nameBookInit = useSelector((state) => state.objectReducer.name);


  const [isGirl, setIsGirl] = useState(genderInit === 'M' ? false : true);

  const handleClickSeeBook = (name_surname) => {
    onClickFunction();
    dispatch(setNameBook(name_surname));
    dispatch(setWish(initialWishText(name_surname)))

    isGirl
      ? dispatch(setGender('W'))
      : dispatch(setGender('M'));

  }

  return <Formik
      initialValues={{
        name_surname: nameBookInit
      }}
      validateOnBlur
  >

    {({values, errors, touched, handleChange, handleBlur}) => (
        <Form>
          <div
              className={(parentClass) ? 'see-the-book ' + parentClass : 'see-the-book'}>
            <label className="field__wrapper">
              <Field
                name="name_surname"
                type="text"
                className={!(touched.name_surname && errors.name_surname) ? 'customer-info__name see-the-book__input-name'
                    : 'customer-info__name see-the-book__input-name error-validation'}
                placeholder="Имя ребёнка"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name_surname}
              />
              {touched.name_surname && errors.name_surname && <span className="error-validation-name">
                {errors.name_surname}
              </span>}
            </label>
            <div className="gender_section__info__toggler see-the-book__toggler">
              <span className="gender_section__info__toggler__boy see-the-book__toggler__boy">
                <i className="boy-sign"/>Мальчик
              </span>
              <span className="gender_section__info__toggler__girl see-the-book__toggler__girl">
                <i className="girl-sign"/>Девочка
              </span>
              <input type="checkbox" className="toggle-button" checked={isGirl}
                     onChange={() => setIsGirl(prevIsGirl => !prevIsGirl)}/>
            </div>
            <Link to={{
              pathname: '/create-book',
              hash: '#1'
            }}>
              <button 
                className="see-the-book__look-book btn-green" 
                id="constructor-link" 
                onClick={() => {
                  handleClickSeeBook(values.name_surname);
                }}
                >
                  Посмотреть книгу
                </button>
            </Link>
          </div>
        </Form>
    )}
  </Formik>
}

export default SeeTheBook
