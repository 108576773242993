import React, { useState } from 'react'
import arrowDownImage from '../../assets/images/faq-modal__question__arrow-down.svg';
import { useDispatch } from 'react-redux';
import { setIsServiceRules } from './../../store/actions/modal-action/modal-action';

function ServiceRules() {
  const dispatch = useDispatch();
  const [openedQuestion, setOpenedQuestion] = useState(-1);

  const onQuestionClick = (question) => {
    setOpenedQuestion(openedQuestion === question ? -1 : question);
  }

  const closeModalServiceRules = () => {
    dispatch(setIsServiceRules(false));
  }

  return (
      <div className="faq-modal modal-scrolled">

        <h2>Правила оказания услуг</h2>

        <div className={'faq-modal__question ' + (openedQuestion === 1 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(1)}>
            <h3>Данные о юридическом лице</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 1 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 1 ? (
              <p>Продажа книг на территории России, Казахстана, Беларуси и Киргизии осуществляется
              компанией ООО &quot;Попал в Переплет&quot; ИП МАХЛИН ДЕНИС ЕВГЕНЬЕВИЧ
                ИНН 781135291613
                ОГРНИП 315784700061591
                ОКПО 0196981840
                Расчетный счет 40802810900001547461
                Банк АО "ТИНЬКОФФ БАНК"
                Юридический адрес банка: Москва, 123060, 1-й Волоколамский проезд, д. 10, стр. 1
                Корр. счет банка 30101810145250000974
                ИНН банка 7710140679
                БИК банка 044525974
                Юридический адрес:
                192076, Санкт-Петербург, Шлиссельбургский пр, д.20, к.2, кв.185</p>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 2 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(2)}>
            <h3>Правила и условия реализации товаров</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 2 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 2 ? (
              <>
                <p>
                  Интернет-магазин «Kids.vpereplet» предоставляет клиентам 100% персонализированные товары – 
                  печатные именные детские книги. Клиент имеет возможность получить полную консультацию 
                  о товаре, процессе заказа и доставки у менеджера магазина – по телефону, 
                  e-mail или на страницах магазина в социальных сетях. При помощи функционала сайта 
                  клиент формирует именную книгу, указывает данные для доставки, оплачивает товар и 
                  отправляет заказ. Перед отправкой товара менеджер магазина связывается с клиентом, 
                  чтобы уточнить информацию об адресе и дате доставки, после чего заказ сразу 
                  отправляется на печать в типографию. Книги изготавливаются в типографии в течение 
                  1-4 рабочих дней и передаются в курьерские компании: “СДЭК”, “Почта России” или 
                  “Новая Почта” для доставки.
                </p>
                <p>
                  Менеджеры интернет-магазина никоим образом не вмешиваются в созданную клиентом книгу– не меняют тексты, обложки и иллюстрации. 
                  После отправки книги на печать её уже нельзя поменять или отменить, изготовленная книга является 100% персональным товаром.
                </p>
                <p>
                  Компании “СДЭК”, “Почта России” и “Новая Почта” осуществляют доставку книг в сроки, согласно своим тарифам и пожеланию клиента. 
                  Клиент имеет возможность отслеживать заказ по трек-номеру, который высылается в письме к заказу, и может менять дату или адрес доставки на сайте курьерской компании. 
                  В день доставки с клиентом связывается курьер, предупреждает о своем приезде и доставляет товар клиенту.
                </p>
                <p>
                  Книги изготавливаются специалистами на профессиональном типографском оборудовании, для печати используется качественная и безопасная бумага и краска, 
                  товар упаковывается в картонную подарочную коробочку с логотипом проекта.
                </p>

              </>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 3 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(3)}>
            <h3>Описание товаров</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 3 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 3 ? (
              <>
                <p>
                  Персональные книги клиенты создают самостоятельно на сайте <a href="https://vpereplet.ru/" target="_blank" rel="noopener noreferrer"> https://kids-vpereplet.ru</a>. 
                  Размер книги “Именная Азбука” 210×297 мм (А4) по горизонтали. Книга доступна в трёх цветах, и в вариантах мягкой и твёрдой обложки, внутри качественная и 
                  плотная глянцевая бумага, ручной шитый переплет, изготавливается в типографии. Упаковка: картонная коробочка. Коробка для доставки упаковывается в пластиковый 
                  пакет курьерской компании. Стоимость товаров при заказе в Россию: Азбука в мягкой обложке 1990 рублей, Азбука в твёрдой обложке 2990 рублей, при заказе 
                  в Украину: Азбука в мягкой обложке 559 гривен, Азбука в твёрдой обложке 829 гривен. 
                </p>

                <p>
                  В случае если печать книги была произведена ненадлежащим образом, была допущена ошибка при сшивании книги, интернет-магазин возвращает клиенту полную стоимость заказа, 
                  либо предлагает повторное изготовление и доставку книги без дополнительной оплаты.
                </p>

                <p>
                  В случае если курьерская компания при доставке сильно повредила посылку и доставила книгу в ненадлежащем виде, интернет-магазин так же возвращает 
                  клиенту полную стоимость заказа, либо предлагает повторное изготовление и доставку книги без дополнительной оплаты.
                </p>

                <p>
                  Возврат книги, изготовленной для клиента на заказ, надлежащего качества не осуществляется в соответствии с постановлением Правительства РФ от 06.02.2002 N 81
                </p>
              </>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 4 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(4)}>
            <h3>Условия и ограничения по доставке товара</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 4 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 4 ? (
              <>
                <p>
                  Доставка товаров осуществляется курьерской компанией СДЭК и Почтой России по России, Казахстану, Беларуси и Киргизии. По территории Украины доставка книг 
                  осуществляется курьерской компанией “Новая Почта”.
                </p>

                <p>
                  Сроки доставки устанавливает курьерская компания, менеджер интернет-магазина оповещает клиента о планируемом сроке доставки по тарифам “СДЭК”, 
                  “Почты России” или “Новая Почта”. Интернет-магазин не несет ответственности за возможную задержку в сроке доставки курьерской компании, но клиент может 
                  обратиться к менеджеру за помощью и информацией об отправленной посылке, а также имеет возможность отслеживать посылку по трек-номеру или связаться с 
                  курьерской компанией напрямую.
                </p>
              </>
          ) : ''}
        </div>

        <div className={'faq-modal__question ' + (openedQuestion === 5 ? 'active' : '')}>
          <div className="faq-modal__question__header link" onClick={() => onQuestionClick(5)}>
            <h3>Информация о принятых мерах обеспечения безопасности операций по картам</h3>
            <img src={arrowDownImage} alt="" className={openedQuestion === 5 ? 'rotate-arrow' : ''}/>
          </div>
          {openedQuestion === 5 ? (
              <p>
                Интернет-магазин «Kids.vpereplet» не видит, не хранит и не передает данные банковских карт клиентов. Оплата заказов онлайн, доставляемых в Россию, Беларусь, 
                Казахстан или Киргизию происходит через сервис Яндекс-Касса с использованием банковских платежных карт. Оплата заказов онлайн, доставляемых в Украину происходит 
                через сервис Stripe с использованием банковских карт. Для осуществления платежа клиенту потребуется сообщить данные его пластиковой карты. Передача этих сведений 
                производится с соблюдением всех необходимых мер безопасности. Данные будут сообщены только на авторизационный сервер по защищенному каналу. При выборе формы оплаты с 
                помощью пластиковой карты проведение платежа по заказу производится непосредственно после его оформления. После завершения оформления заказа в нашем магазине, клиент 
                выбирает вариант оплаты картой, при этом система переключит его на страницу авторизационного сервера, где клиенту будет предложено ввести данные пластиковой карты, 
                после чего будет инициирована авторизация карты, и клиент автоматически будет перемещен на сайт нашего магазина. При этом система уведомит клиента о результатах 
                авторизации. В случае подтверждения авторизации заказ будет выполнен в соответствии с заданными клиентом условиями. В случае отказа в авторизации клиент сможет повторить 
                процедуру оплаты. В случае если карта поддерживает технологию 3D Secure, в процессе авторизации будет запрошен дополнительный одноразовый пароль, способ получения которого зависит от 
                банка. Сумма фактического списания с карты может незначительно варьироваться из-за курса валют и отличаться от указанной на сайте при оплате с территорий РБ, Украины, Казахстана или Киргизии. 
              </p>
          ) : ''}
        </div>

        <button className="btn-green close-modal-button" onClick={() => closeModalServiceRules()}>Закрыть</button>
      </div>
  )
}

export default ServiceRules
