import React from "react";

function TheFooter() {
    return (
        <>
            <footer className="landing">
                <div className="footer__container">
                    <div className="contacts">
                        {/*<div className="contacts__phone">*/}
                        {/*    <span>+7 (985) 189-89-60</span>*/}
                        {/*    <a  href="tel:+7 (985) 189-89-60"> +7 (985) 189-89-60</a>*/}
                        {/*</div>*/}
                        <div className="contacts__email">
                            popal@vpereplet.ru
                        </div>
                    </div>
                    <div className="copyright">
                        © 2020-2021 Попал в переплёт.
                        Все права защищены.
                        <p>Версия VPEREPLET-2.4.21.</p>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default TheFooter