import React from 'react';
import birdImg from '../../assets/images/are-you-sure-exit-modal-bird.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAreYouSureExitModal } from './../../store/actions/modal-action/modal-action';
import {
  setCoverType,
  setNameBook,
  setGender,
  setCharacter,
  setWish,
  setCoverColour,
  setCount
} from './../../store/actions/object-action/object-action';
import {setInitOrder} from "../../store/actions/order-action/order-action";
import {initialWishText} from "../../utils/helpers";
import { setIsRedirect, setRedirectLink } from './../../store/actions/link-action/link-action';
import { setOrderingExitModal } from './../../store/actions/modal-action/modal-action'

function AreYouSureExitModal({areYouSureExitModalFunc, setAreYouSureExitModalFunc}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSetIsRedirect = (isRedir) => {
    dispatch(setIsRedirect(isRedir));
  }

  const handleSetLink = (isRedir) => {
    dispatch(setRedirectLink(isRedir));
  }

  const closeOrderingModal = () => {
    dispatch(setOrderingExitModal(false));
  }

  const closeModalAreYouSure = (e) => {
    handleSetLink('');
    handleSetIsRedirect(false);
    closeOrderingModal();
    dispatch(setAreYouSureExitModal(false));
    setAreYouSureExitModalFunc(null)
  }
  const closeModalAreYouSurAndClearOrder = () => {    
    handleSetIsRedirect(true);
    dispatch(setAreYouSureExitModal(false));
    dispatch(setCoverType('hard'));
    dispatch(setNameBook(''));
    dispatch(setGender('W'));
    dispatch(setCharacter(1));
    dispatch(setCoverColour('pink'));
    dispatch(setCount(1));
    // dispatch(setInitOrder([]));
    dispatch(setWish(initialWishText('')));
    closeOrderingModal();
    if (areYouSureExitModalFunc) areYouSureExitModalFunc(true);
    if (setAreYouSureExitModalFunc) setAreYouSureExitModalFunc(null)
    if (!areYouSureExitModalFunc) history.push('/');
  }
  return <div className="are-you-sure-exit-modal">

    <img src={birdImg} alt=""/>
    <h2>Вы уверены, что хотите прервать создание книги?</h2>
    <div className="are-you-sure-exit-modal__controls">
      <button className="btn-green" onClick={(e) => closeModalAreYouSure(e)}>Нет</button>
      <button className="btn-pink" onClick={(e) => closeModalAreYouSurAndClearOrder(e)}>Да</button>
    </div>
  </div>
}

export default AreYouSureExitModal
