import React from 'react';
import Book from '../components/Book';
import {useSelector} from 'react-redux'

export const BookWrapperStep2 = ({hangleSetCurrentStep}) => {

  const genderInit = useSelector((state) => state.objectReducer.gender);
  const nameBookInit = useSelector((state) => state.objectReducer.name);
  const characterInit = useSelector((state) => state.objectReducer.character);
  const coverColourInit = useSelector((state) => state.objectReducer.cover_colour);
  const wishInit = useSelector((state) => state.objectReducer.wish);

  return (
    <>
      <h1 className="header-block-title constructor">Ваша книга</h1>
      <Book genderData={{
            character: characterInit,
            cover_colour: coverColourInit,
            gender: genderInit,
            name: nameBookInit,
            wish: wishInit,
            page: "INIT",
          }} />
      <div className="btns-block book-mobile book-step ">
          <div className="btn-wrapper">
            <button className="btn-icon btn-white constructor-btn" onClick={() => hangleSetCurrentStep(1)}>
              <i className="white-arrow-left"/>
              Назад
            </button>
          </div>
          <div className="btn-wrapper">
                <button className="btn-icon btn-green constructor-btn" onClick={() => {
                  hangleSetCurrentStep(3);
                  window.ym(73927030,'reachGoal','third-step-button');
                }}
                        id="third-step-link">
                  Далее <i className="white-arrow-right"/>
                </button>
          </div>
      </div>
    </>
  )
};
