import { linkActions } from '../../actions.constants';

export const defaultLinkState = {
  isRedirect: true,
  link: '', 
};

export const linkReducer = (state = defaultLinkState, action) => {
  const { payload, type } = action;
  switch (type) {
    case linkActions.IS_REDIRECT_TO_SMOOTH: {
      return {
        ...state,
        isRedirect: payload,
      };
    }
    case linkActions.GET_REDIRECT_LINK: {
      return {
        ...state,
        link: payload,
      };
    }

    default: {
      return state;
    }
  }
};