import { objectActions } from './../../actions.constants';
import {initialWishText, getStateSessionStorage, setStateSessionStorage} from "../../../utils/helpers";

export const defaultInfoState = {
  object_type: '',
  typeBook: '',
  cover_type: getStateSessionStorage('orderItem')['cover_type'] || 'hard',
  name: getStateSessionStorage('orderItem')['name'] || '',
  gender: getStateSessionStorage('orderItem')['gender'] || 'W',
  character:getStateSessionStorage('orderItem')['character'] || 1,
  wish: getStateSessionStorage('orderItem')['wish'] || initialWishText(''),
  cover_colour: getStateSessionStorage('orderItem')['cover_colour'] || 'pink',
  count: getStateSessionStorage('orderItem')['count'] || 1,
  page: 'INIT',
 //  payment.courier: ''
};

export const defaultInfoStateSessionStorage = {
  object_type: '',
  typeBook: '',
  cover_type: 'hard',
  name:'',
  gender: 'W',
  character:1,
  wish: initialWishText(''),
  cover_colour: 'pink',
  count: 1,
  page: 'INIT',
}

export const objectReducer = (state = defaultInfoState, action) => {
  const { payload, type } = action;
  switch (type) {
    case objectActions.SET_OBJECT_TYPE: {
      return {
        ...state,
        object_type: payload,
      };
    }
    case objectActions.SET_TYPE_BOOK: {
      return {
        ...state,
        typeBook: payload,
      };
    }
    case objectActions.SET_COVER_TYPE: {
      setStateSessionStorage('orderItem','cover_type',payload);
      return {
        ...state,
        cover_type: payload,
      };
    }
    case objectActions.SET_NAME_BOOK: {
      setStateSessionStorage('orderItem','name',payload);
      return {
        ...state,
        name: payload,
      };
    }
    case objectActions.SET_GENDER: {
      setStateSessionStorage('orderItem','gender',payload);
      return {
        ...state,
        gender: payload,
      };
    }
    case objectActions.SET_CHARACTER: {
      setStateSessionStorage('orderItem','character',payload);
      return {
        ...state,
        character: payload,
      };
    }
    case objectActions.SET_WISH: {
      setStateSessionStorage('orderItem','wish',payload);
      return {
        ...state,
        wish: payload,
      };
    }
    case objectActions.SET_COVER_COLOUR: {
      setStateSessionStorage('orderItem','cover_colour',payload);
      return {
        ...state,
        cover_colour: payload,
      };
    }
    case objectActions.SET_COUNT: {
      setStateSessionStorage('orderItem','count',payload);
      return {
        ...state,
        count: payload,
      };
    }
    case objectActions.SET_CURRENT_PAGE: {
      return {
        ...state,
        count: payload,
      };
    }
    default: {
      return state;
    }
  }
};