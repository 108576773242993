import React, {useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'
import menuButtonMobile from '../assets/images/menu-button-mobile.svg'
import closeMobileMenu from '../assets/images/close-mobile-menu-button.svg'
import logo from '../assets/images/logo-main.png'
import { HashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from 'react-redux';
import {
    setIsDelivery,
    setIsFaqModalVisible,
    setAreYouSureExitModal,
    setIsOpenSlide,
    setIsServiceRules,
    setIsPersonalDataModalVisible, setOrderingExitModal
} from './../store/actions/modal-action/modal-action';
import {setCurrentStep, setMax} from "../store/actions/step-action/step-actions";
import {getStateSessionStorage} from "../utils/helpers";


function TheHeader({ isConstructor, parentClass, redirectToSmooth }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isConstructorOpened = location.pathname === '/create-book';

  const isServiceRulesInit = useSelector((state) => state.modalReducer.isServiceRules);
  const isDeliveryInit = useSelector((state) => state.modalReducer.isDelivery);
  const isFaqModalVisibleInit = useSelector((state) => state.modalReducer.isFaqModalVisible);
  const isPersonalDataModalVisibleInit = useSelector((state) => state.modalReducer.isPersonalDataModalVisible);
  const isAreYouSureExitModalnit = useSelector((state) => state.modalReducer.isAreYouSureExitModal);
  // const isOrdingExitModalnit = useSelector((state) => state.modalReducer.isOrdering);


  
  const isOpenSlide = useSelector((state) => state.modalReducer.isOpenSlide);
  const currentStep = useSelector((state) => state.stepReducer.current);

  const orderLength = useSelector((state) => state.orderReducer.order.length);

  const openModalIsServiceRules = (arg) => {
    dispatch(setIsServiceRules(arg));
  }

  const openModalIsPersonalDataModalVisible = (arg) => {
    dispatch(setIsPersonalDataModalVisible(arg));
  }

  const openModalIsDelivery = (isOpen) => {
    dispatch(setIsDelivery(isOpen));
  }
  // const openModalOrdering = (isOpen) => {
  //     dispatch(setOrderingExitModal(false));
  // }

  const openModalIsFaqModalVisible = (isOpen) => {
    dispatch(setIsFaqModalVisible(isOpen));
  }

  const openModalAreYouSure = (isOpen) => {
    dispatch(setAreYouSureExitModal(isOpen));
  }

  const closeSetIsOpenSlide = () => {
    dispatch(setIsOpenSlide(false));
  }

  const openSetIsOpenSlide = () => {
    dispatch(setIsOpenSlide(true));
    dispatch(setOrderingExitModal(false));
  }

  const isAnyModalOpen = 
    isAreYouSureExitModalnit ||
    isPersonalDataModalVisibleInit ||
    isFaqModalVisibleInit ||
    isServiceRulesInit ||
    isDeliveryInit;

  const closeModal =
      isAreYouSureExitModalnit ? () => openModalAreYouSure(false)
    : isPersonalDataModalVisibleInit ? () => openModalIsPersonalDataModalVisible(false)
    : isFaqModalVisibleInit ? () => openModalIsFaqModalVisible(false)
    : isServiceRulesInit ? () => openModalIsServiceRules(false)
    : isDeliveryInit ? () => openModalIsDelivery(false) : () => console.log('No open modal');


  const [screenSize, setScreenSize] = useState(0);


  useEffect(() => {
    setScreenSize(window.innerWidth);
  },[])

  function handleResize(){
    setScreenSize(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize',handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }

  },[]);

  useEffect(() => {
    if (orderLength > 0) dispatch(setMax(5))
  })



const [isBasket, setIsBasket] = React.useState(screenSize > 815 && !isConstructorOpened ? false : true);

const regExp = /#5/;

  React.useEffect(() => {

    if (screenSize > 815 && !isConstructorOpened) {
        setIsBasket(false)  
    } else {
      setIsBasket(true)
    }
  }, [window.innerWidth])

  return (
      <>
        <header
          className={'header' +
          (parentClass ? (' ' + parentClass) : '') +
          (isConstructorOpened
              ? ' constructor-opened' : '') +
          ((isOpenSlide)
              ?  ' mobile-menu-opened' : '')}
        >

          <div className={'container' + (isConstructorOpened ? ' constructor-opened' : '')}>
            <img
              className="header__logo link"
              src={logo}
              alt="lovetoons logo"
              onClick={(e) => {
                if (currentStep === 6) {
                  window.history.pushState({}, '', '/');
                  window.location.reload();

                  return;
                }
                if (isConstructorOpened) {
                    openModalAreYouSure(true);
                }
              }}
            />


            
            {(isOpenSlide || isAnyModalOpen) ? (
                <button
                className="mobile-menu-button"
                onClick={
                  
                  () => isAnyModalOpen
                      ? closeModal()
                      : closeSetIsOpenSlide()}
                >
                  <img src={closeMobileMenu} alt="close menu"/>
                </button>
            ) : (
                <button className="mobile-menu-button" onClick={() => openSetIsOpenSlide(true)}>
                  <img src={menuButtonMobile} alt="open menu"/>
                </button>
            )}
            
            <ul className="header__links">
              <li
                className="link"
                onClick={() => {
                  openModalIsFaqModalVisible(true);
                  window.ym(73927030,'reachGoal','faq-header-button');
                }}
                id="faq-modal"
              >
                Вопрос-ответ
              </li>
              <li
                className="link"
                onClick={() => {
                  openModalIsDelivery(true);
                  window.ym(73927030,'reachGoal','delivery-header-button')
                }}
                id="delivery-modal"
              >
                Доставка
              </li>
              <HashLink 
                smooth to="/#reviewsSection"
                className="link wrapper"
                onClick={(e) => {
                  if (isConstructorOpened) {
                    if (redirectToSmooth) redirectToSmooth(e, "#reviewsSection");
                    // e.preventDefault();
                    openModalAreYouSure(true);
                  }
                  window.ym(73927030,'reachGoal','reviews-header-button');
                }}>
                <li className="link" id="reviews-link">Отзывы</li>
              </HashLink>
              <HashLink 
                smooth to="/#contacts"
                className="link wrapper"
                onClick={(e) => {
                  if (isConstructorOpened) {
                    if (redirectToSmooth) redirectToSmooth(e, "#contacts");
                    // e.preventDefault();
                    openModalAreYouSure(true);
                  }
                  window.ym(73927030,'reachGoal','contacts-header-button');
                }}>
                <li className="link" id="contacts-link">Контакты</li>
              </HashLink>

            </ul>
              {
                // location.hash === '#5'
                //   ? <button
                //       className={'header__order-button-mobile'}
                //       id="constructor-link"
                //     >
                //       <i id="constructor-link"/>
                //     </button>
                //   : 
                  orderLength > 0 &&  <div className={isConstructor ? 'basket-icon' : ''}>              
                      <Link to="/create-book#5"
                        className={'header__order-button-mobile'}
                        onClick={() => {
                          // setIsDelivery(false)
                          // setIsFaqModalVisible(false)
                          // closeSlideOut()
                          dispatch(setCurrentStep(5))
                          // dispatch(setMax(5))
                        }}
                        id="constructor-link"
                      >
                        <i id="constructor-link"/>
                      </Link>
                </div>
              }
            <Link to="/create-book#1" className="header__order-button-link">
              <button id="constructor-link"
                className={isConstructor || isConstructorOpened ? 'header__order-button constructor' : 'header__order-button'}
                onClick={() => {
                  
                
                
                  window.ym(73927030,'reachGoal','landing-cta')
                }}
              >
                Заказать
              </button>

            </Link>
          </div>
        </header>
      </>
  )
}

export default TheHeader
