import React from "react";
import telegramIcon from "../assets/images/icons/social/telegram.png";
import whatsappIcon from "../assets/images/icons/social/whatsapp.png";
import vkIcon from "../assets/images/icons/social/vk.png";
import instagramIcon from "../assets/images/icons/social/instagram.png";
import "./SocialLinks.css";

const SocialIcons = () => {
  return (
      <div>
        <ul className="social-icons">
          <li>
            <a href="https://t.me/Vperepletbot" target="_blank" rel="noopener noreferrer" title="Telegram">
              <img src={telegramIcon} alt="Telegram" />
              <span>Telegram</span>
            </a>
          </li>
          <li>
            <a href="https://wa.me/+79959114143" target="_blank" rel="noopener noreferrer" title="WhatsApp">
              <img src={whatsappIcon} alt="WhatsApp" />
              <span>WhatsApp</span>
            </a>
          </li>
          <li>
            <a href="https://vk.com/popalvpereplet" target="_blank" rel="noopener noreferrer" title="VK">
              <img src={vkIcon} alt="VK" />
              <span>VK</span>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/vpereplet" target="_blank" rel="noopener noreferrer" title="Instagram">
              <img src={instagramIcon} alt="Instagram" />
              *
              <span>Instagram*</span>
            </a>
          </li>
        </ul>
        <br />
        <p className="disclaimer">
          *Социальная сеть Instagram принадлежит компании Meta Platforms Inc.,
          <br/>
          которая запрещена на территории РФ в связи с осуществлением экстремистской деятельности.
        </p>
        <br />
        <div style={{     fontSize: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
           }}>
          Почта: <a href="mailto:popal@vpereplet.ru" style={{ fontSize: "24px" }}>popal@vpereplet.ru</a>
        </div>
      </div>
  );
};

export default SocialIcons;