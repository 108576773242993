import React from 'react';
import {imgLinkToCover} from "../../../utils/helpers";
import {useSelector, useDispatch} from "react-redux";
import styles from './Order.module.scss';
import { countries, countriesСurrency } from './../../../utils/constants';
import { changeCurrentOrderCount } from './../../../store/actions/order-action/order-action';

const Order = ({
    id, gender, name, cover_colour, character, cover_type, price, removeOrder, changeCountBooks
}) => {
    const dispatch = useDispatch()
    const country = useSelector((state) => state.infoClientReducer.country);

    // const [countBook, ]

    const count = useSelector((state) => {
        const order = state.orderReducer.order;
        const index = order.findIndex((i) => i.id === id);
        return order[index].count;
    })
    const getCurrrency = () => {
        let currency;
        if (country === countries[1]) {
            currency = countriesСurrency.uk;
        } else {
            currency = countriesСurrency.ru;
        }
        return currency;
    }

    const countIncrement = (e) => {
        const data = {
            id: id,
            count: count + 1,
        }
        dispatch(changeCurrentOrderCount(data))
        // changeCountBooks(data);
    }
    const countDecrement = (e) => {
        const data = {
            id: id,
            count: count - 1,
        }
        dispatch(changeCurrentOrderCount(data))
        // changeCountBooks(data);
    }
    return (
        <div className={styles.yourOrderWrapper}>

            <div className={styles.yourOrders}>
                <div className={styles.orderItem}>
                    <div className={styles.coverWrapper}>
                        <div className={styles.imgWrapper}>
                        <img
                            src={imgLinkToCover({
                                character: character,
                                cover_colour: cover_colour,
                                gender: gender,
                                name: name,
                                page: "INIT",
                            })}
                            alt="cover"
                            className={styles.coverImg}
                        />
                        </div>
                    </div>
                    <div className={styles.orderItemInfo}>
                        <p className={styles.productName}>
                            Именная азбука
                        </p>
                        <h3 className={styles.productTitle}>
                            {name.toUpperCase()}
                        </h3>
                        <p className={styles.productAdditionalInfo}>
                            {cover_type==='hard'?'Твёрдый переплёт':'Мягкий переплет'}
                        </p>
                    </div>
                    <div className={styles.priceBlock}>
                        <div className={styles.priceInfoWrapper}>
                            <p className={styles.productName}>
                                Стоимость книги
                            </p>
                            <div className={styles.price}>
                                <span className={styles.productTitle}>{price * count}</span>
                                <span> {getCurrrency()}</span>
                            </div>
                            <div className={styles.counter}>
                                <button
                                    type='button'
                                    className={count === 1
                                        ? `${styles.counterMinus} ${styles.disabled}`
                                        // ?[styles.counterMinus,styles.disabled].join(' ')
                                        :styles.counterMinus}
                                    onClick={(e) => countDecrement(e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4" fill="none">
                                        <rect x="11.7451" y="3.39691" width="11.4905" height="2.65165" rx="1.32583" transform="rotate(-180 11.7451 3.39691)" fill="#FE8496"/>
                                    </svg>
                                </button>
                                {/*<input type="number" value={1} min={1}/>*/}
                                <div className={styles.count}>
                                    {count}
                                </div>
                                <button 
                                    type='button'
                                    className={styles.counterIncrease}
                                    onClick={(e) => countIncrement(e)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                        <rect x="9.32568" y="1.32581" width="11.4905" height="2.65165" rx="1.32583" transform="rotate(90 9.32568 1.32581)" fill="#FE8496"/>
                                        <rect x="13.7451" y="8.39691" width="11.4905" height="2.65165" rx="1.32583" transform="rotate(-180 13.7451 8.39691)" fill="#FE8496"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.removeBlock}
                        onClick={(e) => {
                            removeOrder(id);
                        }}
                    >
                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.2188 2.75H12.4375V2.0625C12.4375 0.923398 11.5141 0 10.375 0H7.625C6.4859 0 5.5625 0.923398 5.5625 2.0625V2.75H1.78125C0.832027 2.75 0.0625 3.51953 0.0625 4.46875V5.84375C0.0625 6.22346 0.370285 6.53125 0.75 6.53125H17.25C17.6297 6.53125 17.9375 6.22346 17.9375 5.84375V4.46875C17.9375 3.51953 17.168 2.75 16.2188 2.75ZM6.9375 2.0625C6.9375 1.68352 7.24602 1.375 7.625 1.375H10.375C10.754 1.375 11.0625 1.68352 11.0625 2.0625V2.75H6.9375V2.0625Z" fill="#FE8496"/>
                            <path d="M1.36718 7.90625C1.2445 7.90625 1.14675 8.00877 1.15259 8.13132L1.71978 20.0355C1.7722 21.1372 2.67712 22 3.7797 22H14.2202C15.3228 22 16.2277 21.1372 16.2802 20.0355L16.8474 8.13132C16.8532 8.00877 16.7554 7.90625 16.6328 7.90625H1.36718ZM11.75 9.625C11.75 9.24516 12.0576 8.9375 12.4375 8.9375C12.8173 8.9375 13.125 9.24516 13.125 9.625V18.5625C13.125 18.9423 12.8173 19.25 12.4375 19.25C12.0576 19.25 11.75 18.9423 11.75 18.5625V9.625ZM8.31248 9.625C8.31248 9.24516 8.62013 8.9375 8.99998 8.9375C9.37982 8.9375 9.68748 9.24516 9.68748 9.625V18.5625C9.68748 18.9423 9.37982 19.25 8.99998 19.25C8.62013 19.25 8.31248 18.9423 8.31248 18.5625V9.625ZM4.87498 9.625C4.87498 9.24516 5.18263 8.9375 5.56248 8.9375C5.94232 8.9375 6.24998 9.24516 6.24998 9.625V18.5625C6.24998 18.9423 5.94232 19.25 5.56248 19.25C5.18263 19.25 4.87498 18.9423 4.87498 18.5625V9.625Z" fill="#FE8496"/>
                        </svg>
                        <p className={styles.productName}>
                            Удалить
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Order;